import React from "react"

import {
  emailIcon,
  socialFacebook,
  socialTwitter
} from "../../../../components/Icons"

import {
  FacebookShareButton
} from "react-share"

export default function ShareButtonsList({ name, url }) {
  function popUpWindow(link) {
    window.open(link, "popup", "width=600,height=600")
    return false
  }
  const windowHref = url || window.location.href
  const facebookShareLink = encodeURI(windowHref)
  const twitterShareLink = encodeURI(`https://twitter.com/share?text=Check out this great deal I found on Little Birdie.&hashtags=LittleBirdie&url=${windowHref}`)
  const emailShareLink = encodeURI(`mailto:?subject=Check out this great deal I found on ${window.location.hostname}&body=I just found an awesome deal on ${name}. Check it out at ${windowHref}.`)
  const facebookShareContent = `Check out this great deal I found on Little Birdie. ${windowHref}`
  return (

    <ul className="list-inline share">
      <li className="list-inline-item">
        <a href={emailShareLink}>
          <img src={emailIcon} />
        </a>
      </li>
      <li className="list-inline-item">
        <FacebookShareButton
          url={facebookShareLink}
          quote={facebookShareContent}
          className="link-wrapper">
          <img src={socialFacebook} />
        </FacebookShareButton>
      </li>
      <li className="list-inline-item" style={{ cursor: 'pointer' }}>
        <div onClick={() => popUpWindow(twitterShareLink)}>
          <img src={socialTwitter} />
        </div>
      </li>
    </ul>
  )
}
