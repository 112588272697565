import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import { useSelector } from 'react-redux'

import Loader from '../../Loader'
import ViewSaleButton from '../../Buttons/ViewSaleButton'
import FollowButton from '../../../apps/App/components/buttons/FollowButton'
import { impressionIcon, aboutIcon } from '../../Icons'
import moment from 'moment'
import ReportButton from '../../../apps/App/components/buttons/ReportButton'

import ImageCarousel from '../ImageCarousel'
import PriceHistoryCards from '../../PriceHistoryCards'
import DesktopActionBar from '../../ActionBar/DesktopActionBar'
import StoreBrandLogos from '../StoreBrandLogos'
import NotifyPricedropButton from '../../../apps/App/components/buttons/NotifyPricedropButton'
import dealDataLayer from '../../dataLayers/dealDataLayer'
import OfferComparisonTable from '../OfferComparisonTable'
import ModalWrapper from '../../../apps/App/components/Modals/ModalWrapper'
import changePriceDropLabel from '../../utils/changePriceDropLabel'
import {
  priceDecrease15,
  priceDecrease16to30,
  priceDecrease31plus,
  priceIncrease15,
  priceIncrease16to30,
  priceIncrease31plus
} from '../../../components/Icons'
import { getBeCdnLoaded } from '../../../apps/App/reducers'

export default function DealDetailsCard({ deal, commentsRef, siblingDeals }) {
  const [showMore, setShowMore] = useState(false)
  const clampDescription = useRef()
  const priceHistoryRef = useRef()
  const scrollRef = useRef()
  const [showButton, setShowButton] = useState(false)
  const [showClamp, setClamp] = useState(false)
  const [showComparePrice, setShowComparePrice] = useState(false)
  const cdnLoaded = useSelector(state => getBeCdnLoaded(state))

  if (deal === null) {
    return (
      <div className='listing-detail'>
        <Loader isLoading />
      </div>
    )
  }
  
  // const toggleComparePrice = () => {
  //   setShowComparePrice(!showComparePrice)
  //   window.postMessage({
  //     type: 'toggle-compare-price',
  //     value: !showComparePrice
  //   })
  // }
  
  useEffect(() => {
    const renderShowMoreButton = clampDescription?.current?.clientHeight < clampDescription?.current?.scrollHeight
    setClamp(renderShowMoreButton)

    if(window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'fetch-compare-price', storeUrl: deal.product_url }))
    }
  }, [])

  useEffect(()=> {
    if(cdnLoaded) {
      window.postMessage({
        type: 'fetch-compare-price',
        storeUrl: deal.product_url
      })
    }
  }, [cdnLoaded])

  // ToDo: discount, showDiscount, imageUrls is miisng in api response impressions_count
  const imageUrls = []
  const { id, name, type, discount, showDiscount
    // attributes: {
    //   name,
    //   impressionsCount,
    //   description,
    //   formattedPrice, pricePrev,
    //   discount, showDiscount,
    //   lastPriceDate,
    //   lastUpdate,
    //   stock,
    //   imageUrls,
    //   price
    // },
    // links: {
    //   imageUrl,
    //   affiliateUrl,
    //   offerUrl
    // },
    // relationships: {
    //   store,
    //   brand
    // }
  } = deal

  const store = {
    id: deal.store_id,
    type: "store",
    attributes: {
      id: deal.store_id,
      name: deal.store_name
    },
    links: {
      logoUrl: deal.store_logo
    }
  }

  const brand = {
    id: deal.brand_id,
    type: "brand",
    attributes: {
      name: deal.brand_name
    },
    links: {
      logoUrl: deal.brand_logo
    }
  }


  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  const getDecription = () => {
    try {
      const htmlEscapedDescription = /<\/?[a-z][\s\S]*>/i.test(deal?.description || '') ? '' : deal?.description
      return htmlEscapedDescription
    } catch(e){
      return ''
    }
  }

  const handleViewGraphClick = () => {
    window.scrollTo({ top: priceHistoryRef?.current.offsetTop - 100, behavior: 'smooth' })
  }

  const executeScroll = () => {
    window.scrollTo({ top: scrollRef?.current.offsetTop - 60, behavior: 'smooth' })
  }

  const GotoStoreBtn = (
    <div className='mt-n1 mr-3 pl-2 col-xs-10 col-sm-10 col-md-6 col-lg-6'>
      <ViewSaleButton
        className='btn rounded-pill btn-main btn-goto-store my-1 mr-3 col-xs-12 col-sm-12 col-md-12 col-lg-12'
        url={deal.affiliate_url}
        info={{
          productName: name,
          brandName: deal.store_name, // store?.attributes?.name,
          brandLogo: deal.store_logo //store?.links?.logoUrl
        }}
        type={type || 'deal'}
        text='Go to Store'
        handleClick={() => dealDataLayer(deal, siblingDeals, 'track-event', 'Go to Store')}
      />
    </div>
  )
  const GoToCompareBtn = (
    <div className='row mt-n1 mr-3 pl-2 d-inline-flex flex-wrap'>
      <div className='d-inline-block col-xs-12 col-sm-12 col-md-6 col-lg-6'>
        <ViewSaleButton
          className='btn rounded-pill btn-main btn-goto-store my-1 mr-3 col-xs-12 col-sm-12 col-md-12 col-lg-12'
          url={deal.affiliate_url}
          info={{
            productName: name,
            brandName: deal.store_name, // store?.attributes?.name,
            brandLogo: deal.store_logo //store?.links?.logoUrl
          }}
          type={type || 'deal'}
          text='Go to Store'
          handleClick={() => dealDataLayer(deal, siblingDeals, 'track-event', 'Go to Store')}
        />
      </div>
      <div className='d-inline-block col-xs-12 col-sm-12 col-md-6 col-lg-6'>
        <button
          className='btn rounded-pill btn-outline-dark down my-1 mr-3 col-xs-12 col-sm-12 col-md-12 col-lg-12'
          onClick={executeScroll}
        >
          <div className='mr-3 ml-0'>Compare Prices</div>
        </button>
      </div>
    </div>
  )

  let modifiedCountValue = deal.impressions_count
  if (/^[0-9]*$/.test(deal.impressions_count)) modifiedCountValue = deal.impressions_count > 10 ? deal.impressions_count : ''

  const dollerCents = (price) => {
    const cents = price?.toString()?.split('.')?.[1]
    return cents > 0 ? cents : ''
  }

  const discountText = (discount) => {
    return (discount * 100).toFixed()
  }

  const discountInPercentage = discountText(discount)
  const showDiscountlabel = Math.abs(parseInt(discountInPercentage, 10)) > 1
  const checkValidity = (date) => {
    const selectedDate = moment(date).format('YYYYMMDD')
    const today = moment()
    const diff = today.diff(selectedDate, 'days')
    if (diff <= 28) return true
    else return false
  }
  const formattedPercent = new Intl.NumberFormat('en-US', { signDisplay: 'exceptZero' })
  const numberFormatter = new Intl.NumberFormat('en-US')
  return (
    <>
      <div className='row'>
        <div className='col-lg-6'>
          {
            imageUrls?.length > 0
              ? <ImageCarousel imageUrls={imageUrls} />
              : (
                <div className='image-container mb-3'>
                  <div id='carouselExampleControls' className='carousel slide price-drop-carousel' data-ride='carousel'>
                    <div className='carousel-inner'>
                      <div className='carousel-item active'>
                        <img className='img-fluid d-block w-100' src={`${deal.image_url}#${deal.id}`} />
                      </div>
                    </div>
                  </div>
                </div>
                )
          }
          {
            modifiedCountValue &&
              <div className='detail-view-count-mobile mb-3 float-right'>
                <img src={impressionIcon} className='mr-1' />
                {modifiedCountValue} Views
              </div>
          }
        </div>

        <div className='col-lg-6'>
          <div className='section-row d-flex align-items-center price-label-section-mobile'>
            <div className='price align-items-center'>
              <div className='deal-price-now'>
                <sup className='deal-doller-symbol'>$</sup>{`${numberFormatter.format(Math.floor(deal.price?.toString()?.split('.')?.[0]))}`}<sup className='deal-doller-symbol mx-1'>{`${dollerCents(deal.formatted_price)}`}</sup>
                <img data-toggle='modal' data-target='#deal-price-modal' className='info-icon' src={aboutIcon} />
              </div>
              {showDiscountlabel && checkValidity(lastPriceDate) && (
                <div className='d-flex price-was align-items-center'>
                  <span className='mr-1'>Was </span>
                  <span>
                    {`${pricePrev?.split('.')?.[0]}${pricePrev?.split('.')?.[1] > 0 ? '.' + pricePrev?.split('.')?.[1] : ''}`}
                  </span>
                  <span className='last-price-date'>
                    {((!lastPriceDate || (new Date()) - lastPriceDate) < 30000) ? <div>Just Now</div> : <div>{changePriceDropLabel(moment(lastPriceDate).fromNow())}</div>}
                  </span>
                </div>
              )}
            </div>
            {
              showDiscountlabel && checkValidity(lastPriceDate) &&
                <div className={`deal-detail-price-drop ${discountInPercentage < 0 && 'deal-detail-price-up'}`}>
                  <div className='d-flex align-items-center justify-content-center'>
                    {discountInPercentage > 0 ? <img src={discountInPercentage > 30 ? priceDecrease31plus : discountInPercentage > 15 ? priceDecrease16to30 : priceDecrease15} /> : null}
                    {discountInPercentage < 0 ? <img src={discountInPercentage < -30 ? priceIncrease31plus : discountInPercentage < -15 ? priceIncrease16to30 : priceIncrease15} /> : null}

                    <div className='pricedrop'>
                    {Math.abs(discountInPercentage) > 500 ? discountInPercentage < 500 ? '500%+' : '-500%+' : formattedPercent.format(discountInPercentage*-1) + '%'}
                    </div>

                  </div>
                </div>
            }
            {
              modifiedCountValue &&
                <div className={`detail-view-count ${showDiscountlabel && 'icon-margin'} ml-3 d-none d-lg-block`}>
                  <img src={impressionIcon} className='mr-1' />
                  {modifiedCountValue} Views
                </div>
            }
          </div>
          <div className='section-row'>
            <h2>{name}</h2>
            {getDecription() && (
              <>
                <div className='d-flex justify-content-between detail-terms'>
                  <p className={`text-muted ${showMore && 'remove-clamp'}`} ref={clampDescription}>
                    {getDecription()}
                  </p>
                </div>
                {showClamp && (
                  <a style={{ padding: '0', cursor: 'pointer', fontWeight: 'bold' }} className='text-muted' onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Show less' : 'Show more'}
                  </a>
                )}
              </>
            )}
          </div>
          <div className='section-row deal-detail-actions d-flex'>
            {
              ['discontinued', 'out_of_stock'].includes(deal.stock)
                ? (
                  <div className='mb-4'>
                    Sorry, this deal is no longer available. Be sure to FOLLOW the store and brand to never miss out
                  </div>
                )
                : (showButton ? GoToCompareBtn : GotoStoreBtn)
            }
            <FollowButton
              item={deal}
              buttonClasses='btn btn-follow'
            />
          </div>

          <div className='d-none d-md-block'>
            <DesktopActionBar item={deal} commentsRef={commentsRef} />
          </div>

          <div className='section-row'>
            <div className='section-row h4'>
              This Price Drop is from
            </div>
            <StoreBrandLogos store={store} brand={brand} width={100} imageClassName='d-block profile-img border' />
          </div>
        </div>
      </div>
      {/* {deal && <OfferComparisonTable handleButtonState={(value)=> setShowButton(value)} handleViewGraphClick={handleViewGraphClick} deal={deal} forwardedRef={scrollRef} siblingDeals={siblingDeals} />} */}
      <div className='row price-drop-row'>
        <div className='col-lg-12'>
          <NotifyPricedropButton type={type} id={Number.parseInt(deal.id, 10)} />
        </div>
      </div>
      {/* {deal && <PriceHistoryCards priceHistoryRef={priceHistoryRef} item={deal} siblingDeals={siblingDeals} />} */}
      <div className='row section-row d-block d-md-none'>
        <ReportButton />
      </div>
      <ModalWrapper id='deal-price-modal'>
        <div className='d-flex flex-wrap align-items-center mb-3'>
          <h3 className='m-0 pr-1'>{`${store?.attributes?.name} -`}</h3>
          <h3 className='m-0'> {deal.formatted_price}</h3>
        </div>
        {deal.last_update && <span className='legal-info'>Price last checked at {moment(deal.last_update).format('HH:mm')} on {moment(deal.last_update).format('DD MMM yyyy')}.</span>}
      </ModalWrapper>
    </>
  )
}
