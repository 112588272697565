import React, { useState, useEffect } from "react"
import scrollToRef from "../../components/utils/scrollToRef"
import { Modal } from "react-bootstrap"
import { FacebookShareButton } from "react-share"
import {
  commentIcon,
  upvoteIcon,
  downvoteIcon,
  navHeartIcon,
  navHeartActiveIcon,
  shareIcon,
  upvoteActiveIcon,
  emailIcon,
  socialFacebook,
  socialTwitter
} from "../Icons"
import FollowButton from "../../apps/App/components/buttons/FollowButton"
import MiniVotingButton from "../../apps/App/components/buttons/MiniVotingButton"

export default function MobileListingActionBar({ item, commentsRef }) {
  const { type, id } = item
  const { upvotesCount, downvotesCount, name } = item.attributes || {}
  const [show, setShow] = useState(false)

  const handleShare = () => {
    if (window.ReactNativeWebView) {
      handlNativeShare()
    } else {
      setShow(true)
    }
  }
  const handleClose = () => setShow(false)
  const windowHref = window.location.href
  const facebookShareLink = encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${windowHref}`)
  const twitterShareLink = encodeURI(`https://twitter.com/share?text=Check out this great deal I found on Little Birdie.&hashtags=LittleBirdie&url=${windowHref}`)
  const emailShareLink = encodeURI(`mailto:?subject=Check out this great deal I found on ${window.location.hostname}&body=I just found an awesome deal on ${name}. Check it out at ${windowHref}.`)
  const facebookShareContent = `Check out this great deal I found on Little Birdie. ${windowHref}`

  const handlNativeShare = () => {
    window.ReactNativeWebView?.postMessage(`{"type":"share", "data":${JSON.stringify(item)}, "path":"${location.href}"}`)
  }

  return (
    <div className="detail-action-bar fixed-bottom border-top">
      <div className="nav nav-justified py-2">
        <div className="nav-item text-center">
          <FollowButton item={item} activeIcon={navHeartActiveIcon} inactiveIcon={navHeartIcon} mini className="py-0" />
          <div className="small">Follow</div>
        </div>
        {type !== 'deal' &&
          <>       
            <div className="nav-item text-center">
              <MiniVotingButton type={type} id={id} name={name} image={upvoteIcon} activeImage={upvoteActiveIcon} direction="up" />
              <div className="small text-main">{upvotesCount}</div>
            </div>
            <div className="nav-item text-center">
              <MiniVotingButton type={type} id={id} name={name} image={downvoteIcon} activeImage={upvoteIcon} direction="down" />
              <div className="small">{downvotesCount}</div>
            </div>
            {/* <div className="nav-item text-center" onClick={() => scrollToRef(commentsRef)}>
              <img src={commentIcon} />
              <div className="small">Comment</div>
            </div> */}
          </>
        }
        <div className="nav-item text-center" onClick={handleShare}>
          <img src={shareIcon} />
          <div className="small">Share</div>
        </div>
      </div>

      <Modal
        show={show}
        aria-labelledby="shareListingModalLabel modal fade"
        aria-hidden="true"
        role="dialog"
        tabIndex="-1"
        onHide={handleClose}>
        <Modal.Body>
          <div className="text-center">
            <p className="text-muted mb-2">Share</p>
            <div className="d-inline-block p-2 mx-2 justify-content-center share">
              <a href={emailShareLink}>
                <img src={emailIcon} />
              </a>
            </div>
            <div className="d-inline-block p-2 mx-2 justify-content-center share">
              <FacebookShareButton
                url={facebookShareLink}
                quote={facebookShareContent}
                className="link-wrapper">
                <img src={socialFacebook} />
              </FacebookShareButton>
            </div>
            <div className="d-inline-block p-2 mx-2 justify-content-center share">
              <a href={twitterShareLink} target="blank">
                <img src={socialTwitter} />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
