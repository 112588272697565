import React, { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import queryString from "query-string"

import LoginRedirect from "../../../../components/utils/LoginRedirect"

import Breadcrumb from "../../../../components/ListingPageBreadcrumb"

import DesktopListingDetails from "../../../../components/ListingDetailsCards/DesktopListingDetails"
import Meta from "../../../../components/Meta"
import dealDataLayer from "../../../../components/dataLayers/dealDataLayer"
import saleEventDataLayer from "../../../../components/dataLayers/saleEventDataLayer"
import couponDataLayer from "../../../../components/dataLayers/couponDataLayer"

// import Comments from "../Comments"

import { 
  // loadDealPage,
  loadCouponPage,
  loadSaleEventPage
} from "../../actions"
import {
  // getDealBySlug,
  getCouponBySlug, 
  getSaleEventBySlug, 
  getCurrentUser, 
  getSiblingDealIds, 
  getDealsByIds,
  getBaseUrl
} from "../../reducers"

import ListingSideBar from "./ListingSideBar"
import MobileListingActionBar from "../../../../components/ActionBar/MobileListingActionBar"
import ErrorBoundary from "../../utilis/ErrorBoundary"
import { getElasticDealByIdentifier } from '../../requesters/DealsRequester'

function ListingDetailsPage({
  type,
  item,
  siblings,
  location,
  currentSection
}) {
  const currentUser = useSelector(getCurrentUser)
  const commentsRef = useRef(null)
  // const commentPanelRef = useRef(null)
  const params = queryString.parse(location.search)
  const isCba = (params.utm_source || params.UTM_SOURCE || "").toLowerCase() === "cba"
  let message = _.get(item, "attributes.name") ? `You must be logged in to access ${item.attributes.name}` : undefined

  if (isCba) {
      message = _.get(item, "attributes.name") ? item.attributes.name : undefined
  }

  if (item === null) { return (null) }
  if (_.get(item, "attributes.memberContent") === true && !currentUser) {
    return <LoginRedirect prevUrl={item.links.saleEventUrl} message={message} isCba={isCba} />
  }

  const metaOgCopy = {
     deal: `Find the best price on ${item.name || item.attributes?.name} at Little Birdie.`,
     coupon: `Get ${item.attributes?.name} with ${item.attributes?.couponCode} from ${item.attributes?.storeName} only at Little Birdie. Save money with us`,
     saleEvent: `See ${item.attributes?.name} from ${item.attributes?.storeName} only at Little Birdie and save money with us!`
  }[type]

  const metaCopy = {
    deal: `See and save money with the best prices and deals on ${item.name || item.attributes?.name} at Little Birdie.`,
    coupon: `Get ${item.attributes?.name} with ${item.attributes?.couponCode} from ${item.attributes?.storeName} only at Little Birdie. Save money with us`,
    saleEvent: `See ${item.attributes?.name} from ${item.attributes?.storeName} only at Little Birdie and save money with us!`,
  }[type]

  const metaTitle = {
    deal: `${item.name || item.attributes?.name} ${_.get(item, "store_name", "")} ${_.get(item, "brand_name", "")} | Little Birdie`,
    coupon: `${item.attributes?.name} at ${item.attributes?.storeName} with ${item.attributes?.couponCode} | Little Birdie`,
    saleEvent: `${item.attributes?.storeName} ${item.attributes?.name} | Little Birdie`
  }[type]

  return (
    <>
      <div className="d-md-none">
        {
          !window.ReactNativeWebView && (
            <MobileListingActionBar item={item} commentsRef={commentsRef} />
          ) || null
        }
      </div>
      <div className="deal-page listing-detail">
        { item && (
        <Meta
          name="Listing Page"
          description={metaCopy}
          title={metaTitle}
          og_description={metaOgCopy} />
      ) }
        <>
          <div className="container main-wrapper">
            <Breadcrumb listing={item} />
            <section className="main-content">
              <DesktopListingDetails {... {
                type, item, commentsRef, siblings
              }} />
            </section>
          </div>
          <ListingSideBar type={type} item={item} currentSection={currentSection} sectionPosition={2} />
          {/* {type !== 'deal' && 
            <div className="section-group">
              <div className="container">
                <Comments item={item} commentsRef={commentsRef} />
              </div>
            </div>
          } */}
        </>
      </div>
    </>

  )
}

export function DealPage({ match: { params: { slug } }, location }) {
  const dispatch = useDispatch()
  const [execute, setExecute] = useState()
  const [deal, setDeal] = useState(null)
  const apiBaseUrl = useSelector(getBaseUrl)

  const dealStatusCode = useSelector(state => {
    return state?.data?.deals?.getDealStatusCode
  })
  useEffect(() => {
    setExecute(true)
  }, [])

  useEffect(() => {
    if(slug) {
      getElasticDealByIdentifier(apiBaseUrl, slug).then(({response, status}) => {
        if(response?.data) {
          setDeal({
            ...response.data,
            type: 'deal'
          })
        }
      })
    }
    // dispatch(loadDealPage({ identifier: slug }))
  }, [slug])
  // const deal = useSelector(state => getDealBySlug(state, slug))
  // get sibling deals and call dataLayer
  const siblingDealIds = useSelector(state => getSiblingDealIds(state))
  const siblingDeals = useSelector(state => getDealsByIds(state, siblingDealIds))
  if ((!_.isEmpty(siblingDealIds) && deal && !_.isEmpty(siblingDeals) && execute)) {
    dealDataLayer(deal, siblingDeals, "product-view")
    setExecute(false)
  }

  return <ErrorBoundary statusCode={dealStatusCode?.status}><ListingDetailsPage type="deal" item={deal} siblings={siblingDeals} location={location} currentSection={`${deal?.attributes?.name} Deal Page`} /></ErrorBoundary>
}

export function CouponPage({ match: { params: { slug } }, location }) {
  const dispatch = useDispatch()
  const [execute, setExecute] = useState()

  useEffect(() => {
    setExecute(true)
  }, [])

  useEffect(() => {
    dispatch(loadCouponPage({ identifier: slug }))
  }, [dispatch, slug])
  const coupon = useSelector(state => getCouponBySlug(state, slug))
  const couponStatusCode = useSelector(state => {
    return state?.data?.coupons?.getCouponStatusCode
  })
  if (coupon && execute) {
    couponDataLayer(coupon, "coupon-view")
    setExecute(false)
  }
  return <ErrorBoundary statusCode={couponStatusCode?.status}><ListingDetailsPage type="coupon" item={coupon} location={location} currentSection={`${coupon?.attributes?.name} Coupon Page`} /></ErrorBoundary>
}

export function SaleEventPage({ match: { params: { slug } }, location }) {
  const dispatch = useDispatch()
  const [execute, setExecute] = useState()

  useEffect(() => {
    setExecute(true)
  }, [])

  useEffect(() => {
    dispatch(loadSaleEventPage({ identifier: slug }))
  }, [dispatch, slug])
  const saleEvent = useSelector(state => getSaleEventBySlug(state, slug))
  const saleEventStatusCode = useSelector(state => {
    return state?.data?.saleEvents?.getSaleEventStatusCode
  })
  if (saleEvent && execute) {
    saleEventDataLayer(saleEvent, "sale-event-view")
    setExecute(false)
  }
  return <ErrorBoundary statusCode={saleEventStatusCode?.status} messages={saleEventStatusCode?.errorMessage?.[0]}><ListingDetailsPage type="saleEvent" item={saleEvent} location={location} currentSection={`${saleEvent?.attributes?.name} Sale Event Page`} /></ErrorBoundary>
}
