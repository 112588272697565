import { emitter } from '@marvelapp/react-ab-test'

import multivariateDataLayer from '../../../../components/dataLayers/multivariateDataLayer'

const calculateActiveVariant = ({ name, id, variantsNames, version = 1, variantsWeights, variantGroup = 'Selected Variant Group', variants }) => {
  emitter.defineVariants(name, variantsNames, variantsWeights)
  emitter.calculateActiveVariant(name)
  const activeVariant = { experimentName: name, variantName: emitter.getActiveVariant(name) }
  const getVariantId = variants.filter(variant => variant.name === activeVariant.variantName)

  if (activeVariant.variantName) {
    const experimentIdName = `${id} - ${name}`
    const variantChangeId = getVariantId?.[0]?.property || null
    const selectedVariantId = getVariantId?.[0]?.id || null
    variantGroup = variantGroup || `${version}-${activeVariant.variantName}`

    multivariateDataLayer(variantGroup, experimentIdName, variantChangeId, selectedVariantId)
  }
}

export default calculateActiveVariant
