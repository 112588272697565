import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { departmentPath, categoryPath, subcategoryPath } from "../helpers/urlHelpers"

function BreadcrumbLink({ link }) {
  if (link.link) {
    return (
      <li className="breadcrumb-item">
        <Link to={link.link}>{link.name}</Link>
      </li>
    )
  }
  return <li className="breadcrumb-item">{link.name}</li>
}

const linkShape = PropTypes.shape({
  text: PropTypes.string,
  url: PropTypes.string
})

BreadcrumbLink.propTypes = {
  link: linkShape.isRequired
}

function catLinks(links) {
  const departmentSlug = _.get(links, "department.attributes.slug")
  const categorySlug = _.get(links, "category.attributes.slug")
  const subcategorySlug = _.get(links, "subcategory.attributes.slug")
  const breadcrumbLinks = []
  Object.values(links).forEach(link => {
    if (!link) return
    if (link.type === "department") {
      breadcrumbLinks.push({
        name: link.attributes.name,
        link: departmentPath({ departmentSlug })
      })
    } else if (link.type === "category") {
      breadcrumbLinks.push({
        name: link.attributes.name,
        link: categoryPath({ departmentSlug, categorySlug })
      })
    } else if (link.type === "subcategory") {
      breadcrumbLinks.push({
        name: link.attributes.name,
        link: subcategoryPath({ departmentSlug, categorySlug, subcategorySlug })
      })
    } else if (link.type === "store") {
      breadcrumbLinks.push({
        name: link.attributes.name,
        link: link.links.store_url
      })
    }
  })
  return breadcrumbLinks
}

export default function ListingPageBreadcrumb({ listing, store }) {
  const [linksObject, setLinkObject] = useState([])
  useEffect(() => {
    if (_.has(listing, "relationships")) {
      const {
        department,
        category,
        subcategory,
      } = listing.relationships
      const links = {
        department, category, subcategory, store
      }
      setLinkObject(catLinks(links))
    }
  }, [listing])
  if (listing === null) return null
  const currentName = _.get(listing, "name") || _.get(listing, "attributes.name") || _.get(listing, "attributes.subject")
  return (
    <>
      <div className="breadcrumbs d-close d-lg-inline-block">
        <div className="breadcrumb">
          <BreadcrumbLink link={{ name: "Home", link: "/" }} />
          {linksObject.map(link => (link.name ? <BreadcrumbLink key={link.name} link={link} /> : null))}
          {currentName && <span aria-current="page" className="breadcrumb-item active">{currentName}</span>}
        </div>
      </div>


      <div className="dropdown d-lg-none">
        <div className="dropdown-toggle breadcrumbs" id="breadcrumbMenuButton" aria-expanded="false" aria-haspopup="true" data-toggle="dropdown">
          <div className="breadcrumb">
            <BreadcrumbLink link={{ name: "Home", link: "/" }} />
            {linksObject.map(link => (link.name ? <BreadcrumbLink key={link.name} link={link} /> : null))}
            {currentName && <span aria-current="page" className="breadcrumb-item active">{currentName}</span>}
          </div>
        </div>
        <div className="dropdown-menu breadcrumb-menu" aria-labelledby="breadcrumbMenuButton">
          <BreadcrumbLink link={{ name: "Home", link: "/" }} />
          {linksObject.map(link => (link.name ? <BreadcrumbLink key={link.name} link={link} /> : null))}
          {currentName && <span aria-current="page" className="breadcrumb-item active">{currentName}</span>}
        </div>
      </div>
    </>
  )
}

ListingPageBreadcrumb.propTypes = {
  listing: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  })
}

ListingPageBreadcrumb.defaultProps = {
  listing: null
}
