import React, { useState } from 'react'
import axios from "axios"

function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [errors, setErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setErrors([])
    setIsSubmitting(true)

    const fetchData = async userEmailData => {
      const path = `${gon.urls.api}/auth/password_verification`

      return axios.put(path, userEmailData)
        .then(response => {
          setIsSubmitting(false)

          if (response.status !== 200) {
            if (response.data?.errors) {
              setErrors(response.data?.errors)
            } else {
              setErrors([response.data?.error || response.data?.message])
            }
            return null
          }

          window.location.href = "/login"
          return null
        })
        .catch(error => {
          setIsSubmitting(false)
          setErrors(["Error while sending password verification email. Please try again."])
        })
    }
    let userEmailData = {
      "user": {
        email: email
      }
    }
    fetchData(userEmailData)
  }

    return (
      <div className="content">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div className="text-center mt-4 mb-3">
                    <div className="strong">Forgot your password?</div>
                  </div>
                  <form className="new_user" id="new_user" onSubmit={handleSubmit} noValidate="novalidate" acceptCharset="UTF-8">
                    { errors.length > 0 &&
                      (
                        <div className="alert alert-danger">
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                          <h4 className="alert-heading">Error occurred:</h4>
                          <ul className="mb-0">
                            {
                              errors.map(error => <li>{error}</li>)
                            }
                          </ul>
                        </div>
                      )
                    }
                    <div className="form-group">
                      <label htmlFor="user_email">Email</label>
                      <input autoFocus="autofocus" autoComplete="email" className ="form-control" type ="email" value={email} name="user[email]" id="user_email" onChange={e => setEmail(e.target.value)} required />
                    </div>
                    <div className ="form-group">
                      <button type="submit" name="commit" value="Send me reset password instructions" className={`btn btn-main rounded-pill btn-lg ${isSubmitting ? "disabled": ""}`} data-disable-with="Send me reset password instructions">
                        Send me reset password instructions
                      </button>
                    </div>
                  </form>
                  <div class="form-group"><br /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ForgotPassword
