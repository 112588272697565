import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { slide as Menu } from 'react-burger-menu'

import {
  crossIcon, littlebirdieLogoImg
} from '../../../../../components/Icons'
import { hideUserSlider, hideCatSlider } from '../../../actions'
import { getCurrentUser } from '../../../reducers'
import SignedInList from './SignedInList'
import SignedOutList from './SignedOutList'
import { useHistory } from 'react-router-dom'

export default function UserMenuSlider() {
  const dispatch = useDispatch()
  const { showUserMenu } = useSelector(state => state.menu)
  const currentUser = useSelector(getCurrentUser)
  const history = useHistory();

  const [showExtension, setShowExtension] = useState(true);

  const isReactNativeWebView = !!window.ReactNativeWebView;
  const extPermission = window.localStorage?.getItem('ext_permission') === 'true';

  useEffect(() => {
    if (isReactNativeWebView && extPermission) {
      setShowExtension(false);
    }
  }, [extPermission])

  if (showUserMenu) {
    return (
      <Menu
        right
        isOpen
        width={450}
        customBurgerIcon={false}
        pageWrapId='page-wrap'
        onClose={() => {
          dispatch(hideCatSlider())
          dispatch(hideUserSlider())
        }}
        customCrossIcon={<img src={crossIcon} />}
        className='user-slider'
      >
        <main id='page-wrap'>
          <div className='side-block'>
            <div
              className='side-block-header d-flex justify-content-between'
              onClick={() => {
                history.push('/')
                dispatch(hideCatSlider())
                dispatch(hideUserSlider())
              }}
            >
              <img src={littlebirdieLogoImg} />
            </div>
            {currentUser ? <SignedInList user={currentUser} showExtension={showExtension} /> : <SignedOutList showExtension={showExtension} />}
          </div>
        </main>
      </Menu>
    )
  }
  return null
}
