import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { gotoUrlRequestStart } from '../../actions'
import { getGotoUrl } from '../../reducers'
import { lbBirdFlyLine, CBADiamond, LBBirdText, tickWhite } from '../../../../components/Icons'
import { toast } from 'react-toastify'
import { getSnowplowDomainId } from '../../../../helpers/getSnowplowIds'
import axios from 'axios'
import { gon } from '../../../../../constant'
import Loader from '../../../../components/Loader'
import { getElasticDealByIdentifier } from '../../requesters/DealsRequester'

export default function GoToInfoPage (props) {
  const [isTime, setIsTime] = useState(false)
  const [loader, setloader] = useState(true)
  const [storeData, setStoreData] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const gotoUrl = useSelector(state => getGotoUrl(state))
  const toastId = 'invalid-route-error'
  const containerId = 'main'
  const toastOptions = {
    toastId,
    containerId
  }
  const url = window?.location?.pathname

  // To fetch store data from slug
  useEffect(async () => {
    const type = url?.split('/')?.[2]
    const slug = url?.split('/')?.[3]
    const isType = (type) => {
      switch (type) {
        case 'sale-event':
          return 'sale_events'
        case 'voucher':
          return 'coupons'
        case 'deal':
          return 'deals'
        default:
          return false
      }
    }
    const isTypeValue = isType(type)
    let data
    if  (isTypeValue == 'deals') {
      data = await getElasticDealByIdentifier(gon.urls.api, slug)
    } else {
      data = await axios.get(`${gon.urls.api}/${isTypeValue}/${slug}`)
    }
    if (data?.status === 200 || data?.status === 201) {
      const storeData = data?.data || data?.response?.data
      if (storeData) {
        setStoreData({
          ...storeData,
          isTypeValue
        })
        setloader(false)
      }
    }
  }, [url])

  const spSessionIds = getSnowplowDomainId() // get snowplow session ids

  // To call goto api url
  useEffect(() => {
    if (storeData) {
      if (storeData.isTypeValue !== 'deals') {
        dispatch(gotoUrlRequestStart({ url: storeData?.data?.links?.affiliate_url + `${spSessionIds?.domainSessionId && '?session_id=' + spSessionIds.domainSessionId}` }))
      }
      setTimeout(() => {
        setIsTime(true)
      }, 2000)
    } else {
      toast.error(
        <div>
          <span><i className='fa fa-exclamation mr-2' /></span>
          Sorry the page you requested does not exist test
        </div>,
        toastOptions
      )
      history.push('/')
    }
  }, [storeData])

  // redirection to retailer url/site
  useEffect(() => {
    storeData && (storeData.isTypeValue === 'deals' || gotoUrl.url) && isTime && window.location.replace(storeData.isTypeValue === 'deals' ? storeData.affiliate_url : gotoUrl.url)
  }, [gotoUrl.url, isTime, storeData])
  const storeNameLength = storeData?.included?.[1]?.attributes?.name?.length || storeData?.store_name?.length
  const storeName = storeData?.included?.[1]?.attributes?.name || storeData?.store_name

  return (
    <>
      <div className='goto-page-container-cba container'>
        {
          loader ? <div className='loader'>
            <Loader isLoading />
          </div>
            :
          <>
            <div className='fly-container-cba'>
              {
                storeData?.data?.type === 'coupon'
                  ? <div className='code-copy-cba'>
                    <p><img className='tick-image-cba' src={tickWhite} />Code copied</p>
                    <span className={`store-name`}>
                      Flying you to <span className={`${storeNameLength > 6 && 'store-name-align'}`}>{storeName}</span>
                    </span>
                  </div>
                  : <div className='code-copy-cba code-copy-cba-sale-event'>
                    <span className={`store-name-sale-event`}>
                      Flying you to <span className={`${storeNameLength > 6 && 'store-name-align'}`}>{storeName}</span>
                    </span>
                  </div>
              }
              <img className='fly-img-cba' src={lbBirdFlyLine} />
              <div className='d-flex justify-content-between'>
                <img className='from-img-cba' src={CBADiamond} />
                <img className='to-img-cba rounded-circle' src={storeData?.included?.[1]?.links?.logo_url} />
              </div>
            </div>
            <div className='goto-bottom-section'>
              <p className='powered-by'>Powered by</p>
              <img src={LBBirdText} alt='lb-logo' width='125px' height='32px' />
              <div className='get-price'>Get the <span>best price</span></div>
            </div>
          </>
        }
      </div>
    </>
  )
}
