import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Meta from '../../../../../../components/Meta'
import Sidebar from '../Sidebar'
import ActiveSaleEvents from '../../../calendar/ActiveSaleEvents'
import UpcomingSaleEvents from '../../../calendar/UpcomingSaleEvents'
import { getCurrentUser } from '../../../../reducers'
import { LOAD_SALE_EVENT_CALENDAR_PAGE } from '../../../../actions/types'

export default function CalendarPage () {
  const currentUser = useSelector(getCurrentUser)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: LOAD_SALE_EVENT_CALENDAR_PAGE })
  }, [])

  return (
    <div className="profile-calendar-page settings-page">
      <Meta
        name='Calendar Page'
        title={`My Calendar | ${currentUser?.attributes?.username || ''} | Little Birdie`}
        description={`See ${currentUser?.attributes?.username || ''} on Little Birdie. See all their price drops, price drops, votes, vouchers, sale events and more!`}
      />
      <div className="container">
        <div className="row main-wrapper">
          <div className="col-lg-3 sidebar">
            <Sidebar />
          </div>
          <div className="col ml-auto main-content">
            <section className="">
              <h3>Active Sales</h3>
              <p className="">Dive into the hottest sales that are on right now!</p>
              <ActiveSaleEvents
                currentUser={currentUser} />
            </section>
            <section className="my-5 top-sale-events">
              <h3>Upcoming Sales</h3>
              <p className="">
                We&apos;ll send you a notification to ensure you don&apos;t miss out on these amazing sales!
              </p>
              <UpcomingSaleEvents />
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
