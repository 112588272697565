import React, { Fragment, useState, useEffect, useRef } from 'react'
import $ from 'jquery'

import ViewSaleButton from '../../Buttons/ViewSaleButton'
import FollowButton from '../../../apps/App/components/buttons/FollowButton'
import { calendarIcon } from '../../Icons'
import StoreBrandLogos from '../StoreBrandLogos'
import FlareList from '../../../apps/App/components/lists/FlareList'
import ReportButton from '../../../apps/App/components/buttons/ReportButton'
import DesktopActionBar from '../../ActionBar/DesktopActionBar'
import saleEventDataLayer from '../../dataLayers/saleEventDataLayer'

export default function SaleEventDetailsCard ({ saleEvent, commentsRef }) {
  const [showMore, setShowMore] = useState(false)
  const clampDescription = useRef()
  const [showClamp, setClamp] = useState(false)

  const {
    attributes: {
      name,
      description,
      canJoinSale,
      expiredSaleMessage,
      availability,
      tagNames,
      durationPeriod
    },
    links: {
      imageUrl, affiliateUrl
    },
    relationships: {
      store
    }
  } = saleEvent
  $('[data-toggle="popover"]').popover()

  useEffect(() => {
    const renderShowMoreButton = clampDescription?.current?.clientHeight < clampDescription?.current?.scrollHeight
    setClamp(renderShowMoreButton)
  }, [])

  return (
    <>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='image-container'>
            <img className='img-fluid sale-detail-image' src={imageUrl} />
          </div>
        </div>
        <div className='col-lg-6'>
          <FlareList availability={availability} tags={tagNames} />
          <h2>{name}</h2>
          {description && (
            <>
              <div className='d-flex justify-content-between detail-terms flex-direction-col mb-3'>
                <p className={`text-muted ${showMore && 'remove-clamp'}`} ref={clampDescription}>
                  {description}
                </p>
                {showClamp && (
                  <a style={{ padding: '0', cursor: 'pointer', fontWeight: 'bold' }} className='text-muted' onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Show less' : 'Show more'}
                  </a>
                )}
              </div>
            </>
          )}
          <div className='sale-date'>
            {durationPeriod}
          </div>
          <div className='section-row component deal-detail-actions'>
            {
              canJoinSale && affiliateUrl && (
                <ViewSaleButton
                  className='btn rounded-pill btn-main'
                  url={affiliateUrl}
                  type='sale_event'
                  info={{ productName: name, brandName: store?.attributes?.name, brandLogo: store?.links?.logoUrl }}
                  text='Go to Sale'
                  handleClick={() => saleEventDataLayer(saleEvent, 'track-event', 'Go to Sale')}
                />
              )
            }
            {
              !canJoinSale && !expiredSaleMessage && (
                <FollowButton
                  item={saleEvent}
                  buttonClasses='btn rounded-pill btn-main'
                  activeIcon={calendarIcon}
                  central
                  activeText=' Added to Calendar'
                  inactiveIcon={calendarIcon}
                  inactiveText=' Add to Calendar'
                  follow_type='calendar'
                  handleClick={() => saleEventDataLayer(saleEvent, 'track-event', 'Add to Calendar')}
                />
              )
            }
            {
              expiredSaleMessage && (
                <>
                  <span>{expiredSaleMessage}</span>
                  <br />
                </>
              )
            }
          </div>
          <DesktopActionBar item={saleEvent} commentsRef={commentsRef} />
        </div>
      </div>
      <div className='section-row h4 mt-4'>This Sale is from</div>
      <StoreBrandLogos store={store} width={100} imageClassName='d-block profile-img border' />
      <div className='row section-row d-block d-md-none'>
        <ReportButton />
      </div>
    </>
  )
}
