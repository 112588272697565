import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../../components/Loader'
import { DealCard } from '../../../../../components/ElasticListingCards'

import { getBaseUrl } from '../../../reducers'
import { useInfiniteScroll } from '../../BiInfiniteScroll/useInfiniteScroll'
import useElementOnScreen from '../../BiInfiniteScroll/useElementOnScreen'
import { getElasticResults } from '../../../requesters/'
import { PER_PAGE_LIMIT } from '../../../../../../constant'
import useElementOnDOMTree from '../../BiInfiniteScroll/useElementOnDOMTree'
import { useMediaQuery } from 'react-responsive'

const LoadMoreSpinner = (
  <div className='col-12 m-3 d-flex justify-content-center'>
    <Loader isLoading />
  </div>
)

function NoMoreDivider ({ countZero }) {
  return (
    <div className='col-12 text-center'>
      {countZero
        ? (
          <div>No matches found</div>
          )
        : (
          <div>
            No More Results
            {/* {reachedLimit && ', Please refine your search'} */}
          </div>
          )}
      <hr className='mt-0' />
    </div>
  )
}

export default function SearchResults ({
  keyword, order, value,
  filterOptions, handleStateUpdate,
  searchMeta, isFilter,
  handleSearchFilterDataLayer, mbColumn
}) {
  const baseUrl = useSelector(getBaseUrl)
  const getCurrentPage = JSON.parse(window.localStorage.getItem('search_product_page') || '{}')
  const {
    brandNames,
    storeNames,
    departmentNames,
    categoryNames,
    subcategoryNames,
    paymentMethods,
    priceRange,
    discountRange,
    lastPriceRange,
    attributeFilters
  } = filterOptions

  const params = {
    id: 'deal_search_v2',
    query: keyword,
    per_page: PER_PAGE_LIMIT,
    from: PER_PAGE_LIMIT * 0,
    order,
    value,
    store_names: storeNames,
    brand_names: brandNames,
    department_names: departmentNames,
    category_names: categoryNames,
    subcategory_names: subcategoryNames,
    by_payment_methods: paymentMethods,
    price: priceRange || [0, null],
    price_shift: discountRange.map((e) => e * -1).reverse(),
    last_price_date: lastPriceRange,
    force_fallback: getCurrentPage.page && true,
    attribute_filters: attributeFilters
  }

  const [
    items,
    isNextLoading,
    isPreLoading,
    itemUsers,
    preItems,
    preItemUsers,
    pageNo,
    prePageNo,
    isDataFinished,
    initPage,
    loadNext,
    loadPre,
    loadNew,
    resp
  ] = useInfiniteScroll({
    baseUrl,
    method: getElasticResults,
    params,
    storedPage: getCurrentPage.page || 0
  })

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }
  const showMobileColumn = useMediaQuery({ query: '(max-width: 575px)' })

  useElementOnDOMTree({ id: getCurrentPage?.id })

  const [nextRef, isNextVisible] = useElementOnScreen(options)
  const [preRef, isPreVisible] = useElementOnScreen(options)

  useEffect(() => {
    if (pageNo > 0 && !isNextLoading && isNextVisible && !isDataFinished) {
      loadNext()
    }
  }, [isNextVisible, pageNo])

  useEffect(() => {
    if (prePageNo >= 0 && !isPreLoading && isPreVisible) {
      loadPre()
    }
  }, [isPreVisible, prePageNo])

  useEffect(() => {
    if (resp?.counts) {
      const { counts } = resp
      if (Object.keys(counts).length) {
        const newState = {}
        newState.brandCount = counts.brands
        newState.storeCount = counts.stores
        if (!isFilter && !order && !value) {
          if (counts.deals) {
            newState.isDealEnabled = true
          } else {
            newState.isDealEnabled = false
          }
          if (counts.stores) {
            newState.isStoreEnabled = true
          } else {
            newState.isStoreEnabled = false
          }
          if (counts.brands) {
            newState.isBrandEnabled = true
          } else {
            newState.isBrandEnabled = false
          }
        }

        newState.dealCount = counts.deals || 0
        newState.isFilter = false
        newState.isDealReady = true
        handleStateUpdate(newState)
      }
    }
  }, [resp])

  useEffect(() => {
    const storeItems = []
    const brandItems = []

    preItems?.forEach(item => {
      item.type === 'store' && storeItems.push(item)
      item.type === 'brand' && brandItems.push(item)
    })

    items?.forEach(item => {
      item.type === 'store' && storeItems.push(item)
      item.type === 'brand' && brandItems.push(item)
    })

    handleStateUpdate({
      brands: brandItems,
      stores: storeItems
    })
  }, [items, preItems])

  useEffect(() => {
    async function loadData () {
      if (keyword && !isNextLoading) {
        const resp = getCurrentPage.id && !getCurrentPage.check ? await loadNext() : await loadNew()
        if (!getCurrentPage.check) {
          getCurrentPage.check = true
          typeof getCurrentPage.page === 'number' && getCurrentPage.page >= 0 && window.localStorage.setItem('search_product_page', JSON.stringify({ ...getCurrentPage }))
        }
        handleSearchFilterDataLayer(resp, 'deal')
      }
    }
    loadData()
  }, [brandNames,
    storeNames,
    departmentNames,
    categoryNames,
    subcategoryNames,
    paymentMethods,
    priceRange,
    discountRange,
    lastPriceRange, order, value, searchMeta, keyword, attributeFilters])

  useEffect(() => {
    handleStateUpdate({ isLoading: isNextLoading })
  }, [isNextLoading])

  const isFilterOptionAvailable = () => {
    const availableFilter = { storeNames, brandNames, departmentNames, categoryNames, subcategoryNames, priceRange, discountRange, paymentMethods, lastPriceRange }
    const options = {}
    Object.keys(availableFilter).forEach(li => {
      if (availableFilter[li].constructor === Array && availableFilter[li].length) {
        options[li] = availableFilter[li]
      } else if(typeof availableFilter[li] === 'object' && !Array.isArray(availableFilter[li])) {
        options[li] = availableFilter[li]
      }
    })
    return {
      options,
      isAvailable: Object.keys(options).length
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      removeStorePage()
    })
    return () => {
      window.removeEventListener('beforeunload', () => null)
      removeStorePage()
    }
  }, [])

  const removeStorePage = () => {
    const getPage = JSON.parse(window.localStorage.getItem('search_product_page') || '{}')
    getPage.check && window.localStorage.removeItem('search_product_page')
  }

  const hanldeStorePagination = (pos, id) => {
    const options = isFilterOptionAvailable()
    const quotient = pos / PER_PAGE_LIMIT
    const remainder = pos % PER_PAGE_LIMIT
    const page = Math.floor(remainder === 0 ? quotient - 1 : quotient)
    options.isAvailable && window.localStorage.setItem('search_product_filter', JSON.stringify(options.options))
    window.localStorage.setItem('search_product_page', JSON.stringify({ page, id }))
  }

  return (
    <>
      {isDataFinished && !(isNextLoading || isPreLoading) && items?.length === 0
        ? <span>Oops! No results found. Try using different filters.</span>
        : (
          <div className={`row ${showMobileColumn && 'listing-grid search-result'}`}>
            {isPreLoading && LoadMoreSpinner}
            <div style={{ height: '1px' }} ref={preRef} />
            {preItems.filter(item => item.type === 'deal').map((deal, index) => (
              <DealCard
                deal={deal}
                wrapperClass={`${showMobileColumn ? mbColumn === 'twoColumn' ? 'col-6 col-sm-6 col-lg-4' : 'col-sm-6 col-lg-4' : 'mx-3'}`}
                position={index + 1}
                currentSection='searchResultsList'
                hanldeStorePagination={() => {
                  const dealPage = (initPage * PER_PAGE_LIMIT) - preItems.length + (index + 1)
                  return hanldeStorePagination(dealPage, deal.id)
                }}
                sectionPosition={2}
                key={`deal-${deal.id}`}
                mobileColumn={mbColumn === 'twoColumn' && showMobileColumn}
                user={!(deal.user_name && deal.user_slug && deal.user_avatar_url) ? _.first(preItemUsers.filter(user => user.id === deal.user_id)) : null}
              />
            ))}
            {items.filter(item => item.type === 'deal').map((deal, index) => (
              <DealCard
                deal={deal}
                wrapperClass={`${showMobileColumn ? mbColumn === 'twoColumn' ? 'col-6 col-sm-6 col-lg-4' : 'col-sm-6 col-lg-4' : 'mx-3'}`}
                position={index + 1}
                currentSection='searchResultsList'
                hanldeStorePagination={() => {
                  const dealPage = initPage * PER_PAGE_LIMIT + (index + 1)
                  return hanldeStorePagination(dealPage, deal.id)
                }}
                sectionPosition={2}
                key={`deal-${deal.id}`}
                mobileColumn={mbColumn === 'twoColumn' && showMobileColumn}
                user={!(deal.user_name && deal.user_slug && deal.user_avatar_url) ? _.first(itemUsers.filter(user => user.id === deal.user_id)) : null}
                autoPerformOds={index < 2}
              />
            ))}
            {isNextLoading && LoadMoreSpinner}
            <div style={{ height: '1px' }} ref={nextRef} />
            {isDataFinished && !(isNextLoading || isPreLoading) && <NoMoreDivider countZero={!items.length} />}
          </div>
          )}
    </>
  )
}
