
import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery'

import Loader from '../../Loader'

import ViewSaleButton from '../..//Buttons/ViewSaleButton'
import ReportButton from '../../../apps/App/components/buttons/ReportButton'

import CopyCouponCode from '../../CopyCouponCode'
import StoreBrandLogos from '../StoreBrandLogos'
import FollowButton from '../../../apps/App/components/buttons/FollowButton'
import UniqueCouponClaimField from '../UniqueCouponClaimField'
import DesktopActionBar from '../../ActionBar/DesktopActionBar'
import couponDataLayer from '../../dataLayers/couponDataLayer'

export default function CouponDetailsCard ({ coupon, commentsRef }) {
  const [showMore, setShowMore] = useState(false)
  const clampDescription = useRef()
  const clampMobileDescription = useRef()
  const [showClamp, setClamp] = useState(false)

  if (coupon === null) {
    return (
      <div className='card border-0 shadow-sm py-5'>
        <Loader isLoading />
      </div>
    )
  }

  useEffect(() => {
    const renderShowMoreButton = clampDescription?.current?.clientHeight < clampDescription?.current?.scrollHeight
    const isMobileCheck = clampMobileDescription?.current?.clientHeight < clampMobileDescription?.current?.scrollHeight
    setClamp(renderShowMoreButton || isMobileCheck)
  }, [])

  const {
    attributes: {
      name,
      description,
      availability,
      availableForUse,
      code,
      couponCode,
      type,
      expired
    },
    relationships: {
      store
    },
    links: {
      imageUrl,
      affiliateUrl
    }
  } = coupon
  $('[data-toggle="popover"]').popover()

  const isUnique = (type === 'UniqueCoupon')

  // const DEFAULT_TERMS_CONDITIONS = 'See retailers website for full terms and conditions'

  let viewSales = (
    <ViewSaleButton
      className='btn rounded-pill btn-main'
      url={affiliateUrl}
      info={{ productName: name, brandName: store?.attributes?.name, brandLogo: store?.links?.logoUrl, couponCode }}
      text='Copy Code &amp; Shop Now'
      type={type || 'coupon'}
      codeToCopy={code || couponCode}
      handleClick={() => couponDataLayer(coupon, 'track-event', 'Use Voucher')}
    />
  )

  if (expired) {
    viewSales = (
      <div className='btn rounded-pill btn-outline-dark'>
        Voucher Expired
      </div>
    )
  }

  if (!availableForUse) {
    viewSales = (
      <div className='btn rounded-pill btn-outline-dark'>
        This voucher is not yet available
      </div>
    )
  }

  if (availability.length > 0 && ['discontinued', 'out_of_stock'].includes(availability[0])) {
    viewSales = (
      <div>
        Sorry, this Voucher is no longer available. Be sure to FOLLOW the store and brand to never miss out
      </div>
    )
  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='image-container'>
            <img className='img-fluid coupon-detail-image' src={imageUrl} />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='section-row'>
            <h2>{name}</h2>
            <>
              {description && (
                <div className='d-none justify-content-between d-md-block detail-terms'>
                  <p className={`text-muted ${showMore ? 'remove-clamp' : ''}`} ref={clampDescription}>
                    {description}
                  </p>
                  {showClamp && (
                    <a style={{ padding: '0', cursor: 'pointer', fontWeight: 'bold' }} className='text-muted' onClick={() => setShowMore(!showMore)}>
                      {showMore ? 'Show less' : 'Show more'}
                    </a>
                  )}
                </div>
              )}
            </>
          </div>
          <div className='section-row'>
            {
              isUnique
                ? <UniqueCouponClaimField uniqueCoupon={coupon} />
                : (
                  <>
                    {
                      availableForUse
                        ? <CopyCouponCode
                            {...{
                              item: coupon,
                              wrapperClass: 'd-inline-flex align-items-center justify-content-between coupon-code ',
                              textClass: 'true'
                            }}
                          />
                        : ''
                    }
                    <div className='deal-detail-actions unique-coupon-actions d-flex'>
                      {viewSales}
                      <FollowButton
                        item={coupon}
                        activeText='Saved'
                        inactiveText='Save Voucher'
                        buttonClasses='btn btn-follow-coupon rounded-pill btn-outline-dark d-none d-md-block'
                      />
                    </div>
                    <div className='deal-detail-actions unique-coupon-actions d-block d-md-none mt-n4'>
                      <FollowButton
                        item={coupon}
                        activeText='Saved'
                        inactiveText='Save Voucher'
                        buttonClasses='btn btn-follow-coupon rounded-pill btn-outline-dark'
                      />
                    </div>
                  </>
                  )
            }
          </div>
          <DesktopActionBar item={coupon} commentsRef={commentsRef} />
          <div className='section-row d-block d-md-none'>
            {description && (
              <div className='d-flex justify-content-between detail-terms flex-direction-col'>
                <p className={`text-muted ${showMore && 'remove-clamp'}`} ref={clampMobileDescription}>
                  {description}
                </p>
                {showClamp && (
                  <a style={{ padding: '0', cursor: 'pointer', fontWeight: 'bold' }} className='text-muted' onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Show less' : 'Show more'}
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='section-row h4 mt-4'>
        This voucher is from
      </div>
      <StoreBrandLogos store={store} width={100} imageClassName='d-block profile-img border' />
      <div className='row section-row d-block d-md-none'>
        <ReportButton />
      </div>
    </>
  )
}
