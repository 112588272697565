import React from 'react'

import scrollToRef from '../../components/utils/scrollToRef'
import {
  commentIcon
} from '../../components/Icons'
import MiniVotingButton from '../../apps/App/components/buttons/MiniVotingButton'
import ShareButtonsList from '../../apps/App/components/buttons/ShareButtonsList'
import ReportButton from '../../apps/App/components/buttons/ReportButton'

export default function DesktopActionBar ({ item, commentsRef }) {
  const { type, id } = item
  const { upvotesCount, downvotesCount, commentsCount, name  } = item.attributes || {}
  
  return (
    <div className='rate-this-deal'>
      <div className='d-flex action-wrapper'>
        {type !== 'deal' &&
          <div className='d-flex align-items-center action-list'>
            <div className='h5'>Rate</div>
            <ul className='list-inline rate'>
              <li className='list-inline-item'>
                <div className='btn btn-upvote'>
                  <MiniVotingButton type={type} id={id} name={name} imgClass='btn-rate' direction='up' />
                  <span className=''>{upvotesCount}</span>
                </div>
              </li>
              <li className='list-inline-item'>
                <div className='btn btn-downvote'>
                  <MiniVotingButton type={type} id={id} name={name} imgClass='btn-rate' direction='down' />
                  <span className=''>{downvotesCount}</span>
                </div>
              </li>
              {/* <li className='list-inline-item'>
                <div className='btn btn-downvote' onClick={() => scrollToRef(commentsRef)}>
                  <img className='btn-rate' src={commentIcon} />
                  <span className=''>{commentsCount}</span>
                </div>
              </li> */}
            </ul>
          </div>
        }
        <div className='d-flex align-items-center action-list'>
          <div className='h5'>Share</div>
          {/* <ul className='list-unstyled mb-0 share'> */}
          <ShareButtonsList name={name} />
          {/* </ul> */}
        </div>
        <div className='d-flex align-items-center'>
          <ReportButton />
        </div>
      </div>
    </div>
  )
}
