import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { gotoUrlRequestStart } from '../../actions'
import { getGotoUrl } from '../../reducers'
import { lbBirdFlyLine, littlebirdieLogo, tickWhite } from '../../../../components/Icons'
import { toast } from 'react-toastify'
import { getSnowplowDomainId } from '../../../../helpers/getSnowplowIds'

export default function GoToInfoPage (props) {
  const [isTime, setIsTime] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const gotoInfo = JSON.parse(window.localStorage.getItem('goto_info'))
  const gotoUrl = useSelector(state => getGotoUrl(state))
  const toastId = 'invalid-route-error'
  const containerId = 'main'
  const toastOptions = {
    toastId,
    containerId
  }
  const spSessionIds = getSnowplowDomainId() // get snowplow session ids
  useEffect(() => {
    if (gotoInfo) {
      if (gotoInfo?.type !== 'deal') {
        dispatch(gotoUrlRequestStart({ url: gotoInfo.url + `${spSessionIds?.domainSessionId && '?session_id=' + spSessionIds.domainSessionId}` }))  
      }
      setTimeout(() => {
        setIsTime(true)
      }, 2000)
    } else {
      toast.error(
        <div>
          <span><i className='fa fa-exclamation mr-2' /></span>
          Sorry the page you requested does not exist
        </div>,
        toastOptions
      )
      history.push('/')
    }
  }, [])

  useEffect(() => {
    gotoInfo && (gotoInfo.type === 'deal' || gotoUrl.url) && isTime && window.location.replace(gotoInfo.type === 'deal' ? gotoInfo.url : gotoUrl.url)
    gotoInfo && (gotoInfo.type === 'deal' || gotoUrl.url) && isTime && window.localStorage.removeItem('goto_info')
  }, [gotoUrl.url, isTime, gotoInfo])

  return (
    <>
      <div className='goto-page-container container'>
        <div className='title-card'>
          <h3>Flying you to {gotoInfo?.brandName || ''}</h3>
          {(gotoInfo?.type.toLowerCase() === 'coupon' || gotoInfo?.type.toLowerCase() === 'uniquecoupon') && <div className='d-flex justify-content-between'><p><b>Code:</b> {gotoInfo?.couponCode || ''}</p> <p>copied <img className='tick-image' src={tickWhite} /></p></div>}
        </div>
        <div className='fly-container'>
          <img className='fly-img' src={lbBirdFlyLine} />
          <div className='d-flex justify-content-between'>
            <img className='from-img' src={littlebirdieLogo} />
            <img className='to-img rounded-circle' src={gotoInfo?.brandLogo} />
          </div>
        </div>
        <div>
          {gotoInfo?.type.toLowerCase() === 'sale_event' && <h4 className='text-center'>Save Big:</h4>}
          <h4 className='text-center'>{gotoInfo?.productName || ''}</h4>
          {(gotoInfo?.type.toLowerCase() === 'coupon' || gotoInfo?.type.toLowerCase() === 'uniquecoupon') && <span className='short-description'>Apply code at checkout</span>}
        </div>
      </div>
    </>
  )
}
