import React from "react"

import DesktopCouponDetails from "./Coupon"
import DesktopDealDetails from "./Deal"
import DesktopSaleEventDetails from "./SaleEvent"

export default function DesktopListingDetails({
  type, item, commentsRef, siblings
}) {
  function renderComponent() {
    switch (type) {
      case "deal":
        return <DesktopDealDetails deal={item} commentsRef={commentsRef} siblingDeals={siblings} />
      case "coupon":
        return <DesktopCouponDetails coupon={item} commentsRef={commentsRef} />
      case "saleEvent":
        return <DesktopSaleEventDetails saleEvent={item} commentsRef={commentsRef} />
      default:
        return <div></div>
    }
  }

  return (
    <>
      {renderComponent()}
    </>
  )
}
