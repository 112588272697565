import React from 'react'
import { littlebirdieLogo } from '../../../../../components/Icons'

function index () {
  return (
    <div className='fixed-top main-nav'>
      <nav className='navbar navbar-expand-xl'>
        <div className='container-fluid justify-content-center'>
          <a className='navbar-brand mr-0 bg-transparent' href='/'>
            <img src={littlebirdieLogo} />
          </a>
        </div>
      </nav>
    </div>
  )
}

export default index
