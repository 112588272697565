import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import LoginRedirect from '../../../../components/utils/LoginRedirect'
import {
  upvoteIcon,
  downvoteIcon
} from '../../../../components/Icons'
import {
  getResource,
  getCurrentUser
} from '../../reducers'
import { triggerVoting } from '../../actions'
import { handleNativeRedirectWebView } from '../../utilis/NativeDataShare'

export default function MiniVotingButton ({
  type, id, image, direction, imgClass, buttonClass, activeImage, link, name, onClick, upvoted
}) {
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch()
  const resource = useSelector(state => getResource(state, { type, id }))
  const history = useHistory()

  function handleClick (e) {
    e.preventDefault()
    dispatch(triggerVoting({ type, id, direction }))
    if (onClick) onClick()
  }

  const user = useSelector(getCurrentUser)

  const button = {
    up: {
      class: `${imgClass} ${_.get(resource, 'attributes.voted') ? 'svg-green' : null}`,
      src: (_.get(resource, 'attributes.voted') || upvoted) ? activeImage || upvoteIcon : image || upvoteIcon
    },
    down: {
      class: imgClass,
      src: _.get(resource, 'attributes.downvoted') ? activeImage || upvoteIcon : image || downvoteIcon
    }
  }[direction]

  const handleNativeShare = () => {
    if (!user && !window.ReactNativeWebView) {
      history.push({
        pathname: '/login',
        state: { from: window.location.pathname }
      })
    }

    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"vote", "path":"${window.location.href}"}`)
    }
    // else {
    //   setRedirect(true)
    // }
  }

  if (user) {
    return (
      <button
        type='button'
        className={`btn btn-wrapper ${buttonClass} btn-content`}
        onClick={e => handleClick(e)}
      >
        <img className={button.class} src={button.src} />
      </button>
    )
  }

  if (redirect) return <LoginRedirect prevUrl={link || window.location.pathname} message={`You must be signed in to upvote ${name || 'Listings'}`} />
  return (
    <button
      type='button'
      className={`btn btn-wrapper ${buttonClass} btn-content`}
      onClick={() => handleNativeShare()}
    >
      <img src={direction === 'up' ? image || upvoteIcon : downvoteIcon} className={button.class} />
    </button>
  )
}

MiniVotingButton.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['up', 'down']).isRequired
}
