import React from "react"
import { useSelector } from "react-redux"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import Loader from "../../../../components/Loader"
import UpcomingCard from "./UpcomingCard"

export default function UpcomingSaleEvents() {
  const {
    upcomingSaleEventRequesting,
    upcomingSaleEventIds
  } = useSelector(state => state.saleEventCalendar)

  if (upcomingSaleEventRequesting) return <Loader isLoading={upcomingSaleEventRequesting} />

  return (
    <TransitionGroup appear className="row user-calendar mt-3">
      {upcomingSaleEventIds.map(eventId => (
        <CSSTransition
          timeout={400}
          classNames="tile-transition"
          key={eventId}>
          <UpcomingCard eventId={eventId} />
        </CSSTransition>
      ))}
      {(upcomingSaleEventIds.length === 0 && !upcomingSaleEventRequesting)
        && (
        <CSSTransition
          timeout={400}
          classNames="tile-transition">
          <div className="col">
            No Upcoming Sales
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
