import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import ViewSaleButton from '../../components/Buttons/ViewSaleButton'

import { getBaseUrl, isLoggedIn } from '../../apps/App/reducers'
import { claimUniqueCouponByIdentifier, getUniqueCodeByUser } from '../../apps/App/requesters/UniqueCouponsRequester'
import CopyCouponCode from '../CopyCouponCode'
import { triggerFollowing, triggerUsed } from '../../apps/App/actions'
import ReportButton from '../../apps/App/components/buttons/ReportButton'
import couponDataLayer from '../dataLayers/couponDataLayer'
import { handleNativeRedirectWebView } from '../../apps/App/utilis/NativeDataShare'

export default function UniqueCouponClaim ({ uniqueCoupon }) {
  const {
    type, id,
    attributes: {
      availableForUse,
      followed,
      type: couponType,
      unclaimedCodeCount,
      limitPerCustomer,
      name,
      isCbaExclusive
    },
    links: {
      couponUrl,
      affiliateUrl
    },
    relationships: {
      store
    }
  } = uniqueCoupon

  const dispatch = useDispatch()
  const history = useHistory()
  const userLoggedIn = useSelector(state => isLoggedIn(state))
  const baseUrl = useSelector(getBaseUrl)
  const [selectedCode, setSelectedCode] = useState('')
  const [claimed, setClaimed] = useState(followed)
  const [couponCodes, setCouponCodes] = useState([])
  const [hideButton, setHideButton] = useState(false)
  const toastId = 'invalid-route-error'
  const containerId = 'main'
  const toastOptions = {
    toastId,
    containerId
  }

  const currentCookies = new Cookies()
  const isCbsCookieSet = currentCookies.get(['wtid'])

  const isCba = isCbsCookieSet && isCbaExclusive

  useEffect(() => {
    async function getLastUniqueCode () {
      const { response } = await getUniqueCodeByUser(baseUrl, id)
      if (response.uniqueCode) {
        setClaimed(true)
        Object.keys(response.uniqueCode).forEach(index => setCouponCodes(couponCodes => [...couponCodes, response.uniqueCode[index]]))
      }
    }

    getLastUniqueCode()
  }, [])

  useEffect(() => {
    setSelectedCode(couponCodes[couponCodes.length - 1]?.attributes?.code || couponCodes[couponCodes.length - 1]?.attributes?.couponCode || '')
  }, [couponCodes])

  const handleNativeShare = useCallback(() => {
    const navState = {
      from: {
        prevUrl: couponUrl,
        backCount: -1,
        isCba: isCba,
        message: store?.attributes?.name ? name + ' at ' + store?.attributes?.name : name
      }
    }
    if (window.ReactNativeWebView) {
      navState.from.isCbaExclusive = isCbaExclusive
      handleNativeRedirectWebView(`{"type":"register", "path":"${window.location.href}", "state": ${JSON.stringify(navState)}}`)
    } else {
      history.push({
        pathname: '/register',
        state: navState
      })
    }
  }, [history])

  if (!userLoggedIn) {
    return (
      <>
        <button
          onClick={handleNativeShare}
          className='btn rounded-pill btn-main'
        >
          Login to claim a unique voucher
        </button>
      </>
    )
  }

  if (!availableForUse) {
    return (
      <>
        <div className='btn rounded-pill btn-outline-dark'>
          This voucher is not yet available
        </div>
      </>
    )
  }

  async function claimUniqueCoupon () {
    couponDataLayer(uniqueCoupon, 'track-event', 'Claim Voucher')
    const { response } = await claimUniqueCouponByIdentifier(baseUrl, id)
    if (response.uniqueCode) {
      setClaimed(true)
      setCouponCodes([...couponCodes, response.uniqueCode[Object.keys(response.uniqueCode)[0]]])
      if (!followed) {
        dispatch(triggerFollowing({ type, id }))
      }
    } else {
      setHideButton(true)
      toast.error(
        <div>
          <span><i className='fa fa-exclamation mr-2' /></span>
          Sorry, all the unique vouchers have been claimed
        </div>,
        toastOptions
      )
    }
  }

  function handleClick () {
    const selectedCoupon = couponCodes.filter(couponItem => couponItem?.attributes?.code === selectedCode || couponItem?.attributes?.couponCode === selectedCode)
    if (selectedCoupon.length) dispatch(triggerUsed(selectedCoupon[0]?.id))
    couponDataLayer(uniqueCoupon, 'track-event', 'Use Voucher')
  }

  if (claimed && couponCodes.length > 0) {
    return (
      <>
        <div className='unique-code-checklist'>
          {couponCodes.map((code, i) => (
            <CopyCouponCode
              key={`code-${code.id}`}
              {...{
                item: code,
                wrapperClass: 'd-inline-flex align-items-center justify-content-between coupon-code',
                textClass: 'true',
                checkId: i,
                uniqueCouponType: couponType,
                couponUrl
              }}
            />
          ))}
        </div>

        <div className='section-row deal-detail-actions'>
          <ViewSaleButton
            className='btn rounded-pill btn-main'
            url={affiliateUrl}
            info={{ productName: name, brandName: store?.attributes?.name, brandLogo: store?.links?.logoUrl, couponCode: selectedCode }}
            text='Copy Code &amp; Shop Now'
            type={type || 'coupon'}
            codeToCopy={selectedCode}
            handleClick={handleClick}
          />
          <button
            type='button'
            className='btn rounded-pill btn-outline-dark claimButton'
            style={hideButton || couponCodes.length === limitPerCustomer || unclaimedCodeCount === 0 ? { display: 'none' } : null}
            onClick={() => claimUniqueCoupon()}
          >
            <div className=''>
              Claim another voucher
            </div>
          </button>

        </div>
        <div className='section-row deal-detail-actions d-block d-md-none'>
          <ReportButton />
        </div>
      </>
    )
  }
  if (unclaimedCodeCount > 0) {
    return (
      <>
        <button
          type='button'
          className='btn rounded-pill btn-main'
          style={hideButton ? { display: 'none' } : null}
          onClick={() => claimUniqueCoupon()}
        >
          <div className=''>
            Claim a unique voucher
          </div>
        </button>
      </>
    )
  }
  if (unclaimedCodeCount === 0 && !claimed) {
    return (
      <div>
        Sorry, all the unique vouchers have been claimed
      </div>
    )
  }
  return (
    <div> </div>
  )
}
