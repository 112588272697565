import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { barsIcon, personalisedHeartsWhite, userIcon, homeIcon, chevronLeft } from '../../../../../components/Icons'
import SearchInput from '../SearchInput'
import { showUserSlider } from '../../../actions'
import { handleNativeRedirectWebView } from '../../../utilis/NativeDataShare'

export default function MobileMenuContent({ showSearchBar, isHomepage, currentUser, searchBarSticky }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isSearching, setIsSearching] = useState(false)
  const showMbSearchBar = isHomepage ? showSearchBar : true
  if (!searchBarSticky) { return null }

  const handleNativeLogin = path => {
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"${path}", "path":"${window.location.href}"}`)
    } else {
      history.push({
        pathname: '/' + path,
        state: { from: window.location.pathname }
      })
    }
  }
  const nativeLogoBar = (
    window?.ReactNativeWebView && !(window.location.pathname === '/' || window.location.pathname === '')
      ? (
        <div className={`align-items-center ml-n3 mr-2 ${isSearching ? 'd-none' : 'd-flex'}`}>
          <button className='btn btn-sm' onClick={() => history.goBack()}><img src={chevronLeft} /></button>
          <button className='btn btn-sm pl-0' onClick={() => history.push('/')}><img src={homeIcon} /></button>
        </div>
        )
      : <a className={`navbar-brand bg-transparent mobile-nav ${isSearching ? 'd-none' : 'd-block'}`} href='/' />
  )

  return (
    <div className='container-fluid mobile-menus flex-fill'>
      {nativeLogoBar}
      {
        showMbSearchBar &&
          <div className={`search-wrapper ${isSearching && 'full-size'}`}>
            <SearchInput modifySearchState={setIsSearching} mobileSlider showSearchBar={showMbSearchBar} />
          </div>
      }

      <div className='d-flex justify-content-between'>
        {!currentUser && !currentUser?.type && !isHomepage && !showSearchBar &&
          <>
            <div className='mb-btn-feed' onClick={() => handleNativeLogin('register')}>
              <div className='btn btn-secondary rounded-pill btn-feed'>
                <img src={personalisedHeartsWhite} className='mr-2' width='20px' height='20px' />
                Personalise my feed
              </div>
            </div>
            <div onClick={() => handleNativeLogin('login')}>
              <img src={userIcon} className='mb-man-icon' />
            </div>
          </>}
        <button
          className={`btn btn-link mobile-burger ${isSearching ? 'd-none' : 'd-block'}`}
          type='button'
          onClick={() => { dispatch(showUserSlider()) }}>
          <img src={barsIcon} />
        </button>
      </div>

    </div>
  )
}
