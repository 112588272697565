import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import LoginRedirect from '../../../../components/utils/LoginRedirect'

import { alertIcon, alertFillIcon } from '../../../../components/Icons'
import { getResource, getCurrentUser } from '../../reducers'
import { triggerNotifyPricedrop, triggerFollowing, followingNotifyEnd } from '../../actions'

export default function NotifyPricedropButton ({ type, id }) {
  const dispatch = useDispatch()
  // const {
  //   attributes: {
  //     notifyPricedrop,
  //     followed
  //   }
  // } = useSelector(state => getResource(state, { type, id }))

  // ToDo: map followed deal here
  const notifyPricedrop = false
  const followed = false
  const [recentlyNotified, setRecentlyNotified] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const user = useSelector(getCurrentUser)

  const btnClass = notifyPricedrop ? 'btn-main' : 'btn-outline-dark'
  const btnText = notifyPricedrop ? 'Notified' : 'Notify Me'
  const iconToShow = notifyPricedrop ? alertFillIcon : alertIcon

  const handleClick = () => {
    dispatch(triggerNotifyPricedrop({ identifier: id }))

    !followed && !notifyPricedrop && dispatch(triggerFollowing({ type, id }))

    setTimeout(() => {
      dispatch(followingNotifyEnd({ type, id }))
    }, 3000)

    if (!notifyPricedrop) setRecentlyNotified(true)
  }

  const activeText = recentlyNotified ? `Done! We'll send you a notification by email as soon as the price next drops` : 'You have already selected to be notified'

  const handleNativeShare = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(`{"type":"login", "path":"${window.location.href}"}`)
    } else {
      setRedirect(true)
    }
  }
  if (redirect) return <LoginRedirect prevUrl={window.location.pathname} />

  return (
    <div className='container '>
      <div className='row'>
        <div className='col-lg-9 col-md-12 align-self-center py-3'>
          <h4 className='price-drop-notification'>Price Drop notification</h4>
          <p>
            {notifyPricedrop
              ? activeText
              : `Still not satisfied? We’ll send you an email when the price from this store drops.`}
          </p>
        </div>
        <div className='col-lg-3 col-md-12 align-self-center py-3 notify-me'>
          {
            user
              ? (
                <button
                  className={`btn ${btnClass} rounded-pill`}
                  type='button'
                  onClick={() => handleClick()}
                >
                  <img src={iconToShow} />
                  {' '}
                  {btnText}
                </button>
                )
              : (
                <button className={`btn ${btnClass} rounded-pill`} type='button' onClick={handleNativeShare}>
                  <img src={iconToShow} />
                  {' '}
                  Notify Me
                </button>
                )
          }
        </div>
      </div>
    </div>
  )
}

NotifyPricedropButton.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}
