import _ from "lodash"
import React from "react"
import {
  TopCurrentSalesSidebarList,
  StoreCouponsList
} from "../listings"
// import DealSidebarList from "../listings/DealSidebarList"

export default function ListingSideBar({ type, item, currentSection, sectionPosition }) {
  if (type === "coupon") {
    return (
      <>
        <TopCurrentSalesSidebarList currentSection={currentSection} sectionPosition={sectionPosition + 1} />
      </>
    )
  }
  if (type === "saleEvent") {
    const store = _.get(item, "relationships.store")
    return (
      <>
        {store && <StoreCouponsList store={store} coupon={item} title={`Top Promo Codes from ${store.attributes.name}`} currentSection={`Top Promo Codes from ${store.attributes.name}`} sectionPosition={sectionPosition + 1} />}
        <TopCurrentSalesSidebarList currentSection={currentSection} sectionPosition={sectionPosition + 2} />
      </>
    )
  }
  
  // ToDo: To be removed Similar and more price drops from elastic deal detail page
  // if (type === "deal") {
  //   const store = _.get(item, "relationships.store")
  //   return (
  //     <>
  //       {store && <StoreCouponsList store={store} coupon={item} title={`Top Vouchers from ${store.attributes.name}`} currentSection={`Top Vouchers from ${store.attributes.name}`} sectionPosition={sectionPosition + 1} />}
  //       <DealSidebarList
  //         item={item}
  //         itemSlug={item.attributes.slug}
  //         title='Similar products'
  //         currentSection={`Similar products from ${_.get(item, "relationships.store.attributes.name")}`}
  //         sectionPosition={sectionPosition + 2}
  //       />

  //       <DealSidebarList
  //         item={item}
  //         store={_.get(item, "relationships.store.attributes.slug")}
  //         viewAll={`/shop/store/${_.get(item, "relationships.store.attributes.slug")}/top-price-drops`}
  //         department={_.get(item, "relationships.department.attributes.slug")}
  //         title={`More Price Drops from ${_.get(item, "relationships.store.attributes.name")}`}
  //         currentSection={`More Price Drops from ${_.get(item, "relationships.store.attributes.name")}`}
  //         sectionPosition={sectionPosition + 3}
  //       />
  //     </>
  //   )
  // }

  return null
}
