import React, { Component, Fragment } from "react"

class ImageCarousel extends Component {
  render() {
    const {
      imageUrls
    } = this.props

    return (
      <div className="image-container mb-3">
        <div id="carouselExampleControls" className="carousel slide price-drop-carousel" data-ride="carousel">
          {
              imageUrls && imageUrls.length > 1 && (
                <ol className="carousel-indicators">
                  {
                    imageUrls.slice(0, 3).map((image, index) => (
                    index === 0
                    ? <li key={`li ${index + 1}`} data-target="#carouselExampleIndicators" data-slide-to={index} className="active"></li>
                    : <li key={`li ${index + 1}`} data-target="#carouselExampleIndicators" data-slide-to={index}></li>
                    ))
                  }
                </ol>
              )
            }
          {
            imageUrls && (
              <div className="carousel-inner">
                {
                  imageUrls.slice(0, 3).map((image, index) => (
                    index === 0
                      ? (
                        <div key={`img ${index + 1}`} className="carousel-item active">
                          <img className="img-fluid d-block w-100" src={image} />
                        </div>
                      )
                      : (
                        <div key={`img ${index + 1}`} className="carousel-item">
                          <img className="img-fluid d-block w-100" src={image} />
                        </div>
                      )
                  ))
                }
              </div>
            )
          }
          {
            imageUrls && imageUrls.length > 1 && (
              <Fragment>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </Fragment>
            )
          }
        </div>
      </div>
    )
  }
}

export default ImageCarousel
