import React from "react"
import { useSelector } from "react-redux"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import Loader from "../../../../components/Loader"
import ActiveCard from "./ActiveCard"

export default function ActiveSaleEvents() {
  const {
    activeSaleEventRequesting,
    activeSaleEventIds
  } = useSelector(state => state.saleEventCalendar)

  if (activeSaleEventRequesting) return <Loader isLoading={activeSaleEventRequesting} />

  return (
    <TransitionGroup appear className="row user-calendar mt-3">
      {activeSaleEventIds.map(eventId => (
        <CSSTransition
          timeout={400}
          classNames="tile-transition"
          key={eventId}>
          <ActiveCard eventId={eventId} />
        </CSSTransition>
      ))}
      {(activeSaleEventIds.length === 0 && !activeSaleEventRequesting)
        && (
        <CSSTransition
          timeout={400}
          classNames="tile-transition">
          <div className="col">
            No Active Sales
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
