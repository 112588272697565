import React from "react"
import { AVAILABILITY_OPTIONS_LIST } from "../../../../components/SubmitListingForm/formOptions"

export default function FlareList({ availability, tags }) {
  function prettyAvailability(name) {
    if (name === "") {
      return (null)
    }
    const availabilityOption = AVAILABILITY_OPTIONS_LIST.find(option => option.value === name)
    if (availabilityOption) {
      return availabilityOption.label
    }
    return (null)
  }

  return (
    <ul className="list-inline tags-list">
      {availability && availability.map(name => name !== "" && <li className="list-inline-item" key={name}>{prettyAvailability(name)}</li>)}
      {tags && tags.map(name => <li className="list-inline-item" key={name}>{_.startCase(name)}</li>)}
    </ul>
  )
}
