import React from 'react'
import FollowButton from '../../apps/App/components/buttons/FollowButton'

export default function StoreBrandLogos ({
  brand, store, width, imageClassName
}) {
  return (
    <ul className='list-inline'>
      {(brand && brand.attributes && brand.links?.logoUrl) && (
        <li className='list-inline-item mr-3' style={{ verticalAlign: 'top' }}>
          <div className='listing-brand-store'>
            <div className='wrapper'>
              <img width={width} className={imageClassName} src={brand.links.logoUrl} />
              <FollowButton item={brand} buttonClasses='btn btn-follow' />
            </div>
            <div className='small'>Brand</div>
            <div className='brand-store-name'>
              {brand.attributes.name}
            </div>
          </div>
        </li>
      )}
      {(store && store.attributes && store.links?.logoUrl) && (
        <li className='list-inline-item mr-3' style={{ verticalAlign: 'top' }}>
          <div className='listing-brand-store'>
            <div className='wrapper'>
              <img width={width} className={imageClassName} src={store.links.logoUrl} />
              <FollowButton item={store} buttonClasses='btn btn-follow' />
            </div>
            <div className='small'>Store</div>
            <div className='brand-store-name'>
              {store.attributes.name}
            </div>
          </div>
        </li>
      )}
    </ul>
  )
}
