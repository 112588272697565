export default function dealDataLayer (item, siblingDeals, eventType, linkType) {
  const productId = item.id
  const productName = item.name
  const productUrl = item.product_url

  // ToDo: response must contain below department, category,subcategory
  const departmentName = _.get(item, "relationships.department.attributes.name", null)
  const categoryName = _.get(item, "relationships.category.attributes.name", null)
  const subcategoryName = _.get(item, "relationships.subcategory.attributes.name", null)

  const names = [departmentName, categoryName, subcategoryName]

  const brandId = item.brand_id
  const brandName = item.brand_name
  const storeId = item.store_id
  const storeName = item.store_name

  const siblingDealsSanitized = _.filter(Object.values(siblingDeals || {}), v => _.keys(v).length !== 0)

  if (eventType === "product-view") {
    const deals = siblingDealsSanitized.map(deal => ({
      id: String(deal.id),
      price: deal.price, //parseFloat(_.get(deal, "attributes.price"), 10),
      currency: "AUD",
      rank_global: _.get(deal, "attributes.fixedGlobalScore"),
      rank_department: _.get(deal, "attributes.fixedDepartmentScore")
    }))

    if(deals.length) {
      snowplow('trackStructEvent', 'ux', 'product-view', linkType, null, null, [
        {
          schema: "iglu:com.littlebirdie/product/jsonschema/1-0-0",
          data: {
            id: String(productId),
            name: productName,
            category: names
            }
        },
        {
          schema: "iglu:com.littlebirdie/deal/jsonschema/1-0-0",
          data: { deal: [ ...deals]}
        },
        {
          schema: "iglu:com.littlebirdie/brand/jsonschema/1-0-0",
          data: {
            id: String(brandId),
            name: brandName
          }
        },
        {
          schema: "iglu:com.littlebirdie/retailer/jsonschema/1-0-0",
          data: {
            id: String(storeId),
            name: storeName
          }
        }]
      );
    }
  }

  if (linkType && eventType === "track-event") {

    const deals = siblingDealsSanitized.map(deal => ({
      id: String(deal.id),
      price: parseFloat(_.get(deal, "attributes.price"), 10),
      currency: "AUD",
      url: productUrl?.substring(0, 255) || ''
    }))

    snowplow('trackStructEvent', 'ux', 'product-link-click', linkType, 'featured', 1, [
      {
        schema: "iglu:com.littlebirdie/product/jsonschema/1-0-0",
        data: {
          id: String(productId),
          name: productName,
          category: names
        }
      },
      {
        schema: "iglu:com.littlebirdie/deal/jsonschema/1-0-0",
        data: { deal: [ ...deals]}
      },
      {
        schema: "iglu:com.littlebirdie/brand/jsonschema/1-0-0",
        data: {
          id: String(brandId),
          name: brandName
        }
      },
      {
        schema: "iglu:com.littlebirdie/retailer/jsonschema/1-0-0",
        data: {
          id: String(storeId),
          name: storeName
        }
      }]
    );
  }
}
