/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { reportIcon } from '../../../../components/Icons'
import LoginRedirect from '../../../../components/utils/LoginRedirect'
import { isLoggedIn, getCurrentUser } from '../../reducers'
import getSessionStorageOrDefault from '../../../../components/utils/getSessionStorageOrDefault'
import { handleNativeRedirectWebView } from '../../utilis/NativeDataShare'

export default function ReportButton ({ wrapperClass }) {
  const [showReportModal, setShowReportModal] = useState(getSessionStorageOrDefault('handleShow', false))
  const handleClose = () => { setShowReportModal(false) }
  const userLoggedIn = useSelector(isLoggedIn)
  const user = useSelector(getCurrentUser)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    sessionStorage.setItem('handleShow', JSON.stringify(showReportModal))
  }, [showReportModal])

  if (redirect) {
    sessionStorage.setItem('handleShow', JSON.stringify(true))
    return <LoginRedirect prevUrl={window.location.pathname} />
  }

  const pageUrl = window.location.href
  const username = _.get(user, 'attributes.username')
  const userEmail = _.get(user, 'attributes.email')

  const iframHTML = `<iframe
    id="JotFormIFrame-203296435503049"
    title="Report a Deal"
    onload="window.parent.scrollTo(0,0)"
    allowtransparency="true"
    allowfullscreen="true"
    allow="geolocation; microphone; camera"
    src="https://form.jotform.com/203296435503049?url=${pageUrl}&userid=${username}&useremail=${userEmail}"
    frameborder="0"
    style="
    min-width: 100%;
    height:539px;
    border:none;"
    scrolling="no"
    >
    </iframe>
    <script type="text/javascript">
    var ifr = document.getElementById("JotFormIFrame-203296435503049");
    if(window.location.href && window.location.href.indexOf("?") > -1) {
      var get = window.location.href.substr(window.location.href.indexOf("?") + 1);
      if(ifr && get.length > 0) {
        var src = ifr.src;
        src = src.indexOf("?") > -1 ? src + "&" + get : src  + "?" + get;
        ifr.src = src;
        ifr.src += (ifr.src.indexOf("?") > -1 ? "&" : "?") + "isIframeEmbed=1";
      }
    }
    window.handleIFrameMessage = function(e) {
      if (typeof e.data === 'object') { return; }
      var args = e.data.split(":");
      if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
      if (!iframe) { return; }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          var src = args[1];
          if (args.length > 3) {
              src = args[1] + ':' + args[2];
          }
          var script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if      (window.document.exitFullscreen)        window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
          break;
      }
      var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
      if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
        var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
        iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", handleIFrameMessage);
    }
    </script>
  `
  const handleNativeShare = () => {
    if (userLoggedIn) {
      setShowReportModal(true)
    } else {
      if(window?.ReactNativeWebView) {
        handleNativeRedirectWebView(
          `{"type":"something_wrong", "path":"${window.location.href}"}`
        )
      } else {
        setRedirect(true)
      }
    }
  }

  return (
    <>
      {/* only visible on desktops devices */}
      <div className='d-none d-md-block'>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip id='report-tooltip'><span style={{ fontSize: '14px' }}>Has your eagle-eyed vision found an issue with the product or price we're showing? Be a top spotter and let us know!</span></Tooltip>}
        >
          <button
            type='button'
            className='btn btn-report'
            onClick={() => handleNativeShare()}
          >
            <div className={wrapperClass ? '' : 'd-flex align-items-center'}>
              <span><u>Something wrong?</u></span>
              <img src={reportIcon} />
            </div>
          </button>
        </OverlayTrigger>
      </div>
      {/* only visible on mobile devices */}
      <div className='d-block d-md-none mobile-report-section'>
        <h4>Something wrong?</h4>
        <div className='mt-3 mb-4'>
          Has your eagle-eyed vision found an issue with the product or price we're showing? Be a top spotter and let us know!
        </div>
        <button
          type='button'
          className='d-block d-md-none btn btn-report btn-outline-dark'
          onClick={() => handleNativeShare()}
        >
          <div className={wrapperClass ? '' : 'd-flex align-items-center social-report-button-mobile'}>
            <span>Something wrong?</span>
            <img src={reportIcon} />
          </div>
        </button>
      </div>
      <Modal show={showReportModal} onHide={() => handleClose()}>
        <div className='modal-header border-0 pb-0'>
          <button type='button' className='close' onClick={() => setShowReportModal(false)}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: iframHTML }} />
        </Modal.Body>
      </Modal>
    </>
  )
}
