export default function saleEventDataLayer (saleEvent, eventType, linkType) {
  const {
    id,
    attributes: {
      name: saleEventName,
      startAt,
      expireAt,
      fixedGlobalScore,
      fixedDepartmentScore
    }
  } = saleEvent

  const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
  const startDate = startAt ? startAt.match(regex)[0] : null
  const expireDate = expireAt ? expireAt.match(regex)[0] : null

  const departmentName = _.get(saleEvent, 'relationships.department.attributes.name', null)
  const categoryName = _.get(saleEvent, 'relationships.category.attributes.name', null)
  const subcategoryName = _.get(saleEvent, 'relationships.subcategory.attributes.name', null)

  const names = [departmentName, categoryName, subcategoryName]

  const {
    id: brandId,
    attributes: {
      name: brandName
    } = {}
  } = saleEvent.relationships.brand || {}

  const {
    id: storeId,
    attributes: {
      name: storeName
    } = {}
  } = saleEvent.relationships.store || {}

  if (linkType && eventType === 'track-event') {
    snowplow('trackStructEvent', 'ux', 'sale-event-link-click', linkType, null, null, [
      {
        schema: 'iglu:com.littlebirdie/sale/jsonschema/1-0-0',
        data: {
          id: String(id),
          name: saleEventName,
          category: names,
          start_date: startDate,
          end_date: expireDate
        }
      },
      {
        schema: "iglu:com.littlebirdie/brand/jsonschema/1-0-0",
        data: {
          id: String(brandId),
          name: brandName
        }
      },
      {
        schema: "iglu:com.littlebirdie/retailer/jsonschema/1-0-0",
        data: {
          id: String(storeId),
          name: storeName
        }
      }]
    );
  }

  if (eventType === "sale-event-view") {
    snowplow('trackStructEvent', 'ux', 'sale-event-view', linkType, null, null, [
      {
        schema: "iglu:com.littlebirdie/sale/jsonschema/1-0-0",
        data: {
          id: String(id),
          name: saleEventName,
          category: names,
          start_date: startDate,
          end_date: expireDate,
          rank_global: fixedGlobalScore,
          rank_department: fixedDepartmentScore
        }
      },
      {
        schema: "iglu:com.littlebirdie/brand/jsonschema/1-0-0",
        data: {
          id: String(brandId),
          name: brandName
        }
      },
      {
        schema: "iglu:com.littlebirdie/retailer/jsonschema/1-0-0",
        data: {
          id: String(storeId),
          name: storeName
        }
      }]
    );
  }
}
