import React, { useState } from 'react'
import axios from "axios"
import queryString from "query-string"

function ChangePassword({location}) {
  const params = queryString.parse(location?.search)
  const resetPasswordToken = params?.reset_password_token
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errors, setErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setErrors([])
    setIsSubmitting(true)

    const fetchData = async userPasswordData => {
      const path = `${gon.urls.api}/auth/password_update`

      return axios.put(path, userPasswordData)
        .then(response => {
          setIsSubmitting(true)

          if (response.status !== 200) {
            if (response.data?.errors) {
              setErrors(response.data?.errors)
            } else {
              setErrors([response.data?.error || response.data?.message])
            }
            return null
          }

          window.location.href = "/login"
          return null
        })
        .catch(error => {
          setIsSubmitting(true)
          setErrors(["Error while resetting password. Please try again."])
        })
    }
    let userPasswordData = {
      "token": resetPasswordToken,
      "password": password,
      "password_confirmation": confirmPassword,
    }

    fetchData(userPasswordData)
  }
    return (
      <div className="content">
        <div className="container">
          <h1>Change your password</h1>
          <form className="edit_user" id="edit_user" onSubmit={handleSubmit} acceptCharset="UTF-8" >
            { errors.length > 0 &&
              (
                <div className="alert alert-danger">
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 className="alert-heading">1 error prohibited this user from being saved:</h4>
                  <ul className="mb-0">
                    {
                      errors.map(error => <li>{error}</li>)
                    }
                  </ul>
                </div>
              )
            }
            <div className="form-group">
              <div className="field_with_errors">
                <label htmlFor="user_password">New password</label>
              </div>
              <div className="field_with_errors">
                <input autoFocus="autofocus" className="form-control" type="password" value={password} name="user[password]" id="user_password" onChange={e => setPassword(e.target.value)} />
              </div>
              <small className="form-text text-muted">(6 characters minimum)</small>
            </div>
            <div className="form-group">
              <label htmlFor="user_password_confirmation">
                Confirm new password
              </label>
              <input autoComplete="off" className="form-control" type="password" value={confirmPassword} name="user[password_confirmation]" id="user_password_confirmation" onChange={e => setConfirmPassword(e.target.value)} />
            </div>
            <div className="form-group">
              <button type="submit" name="commit" value="Change my password" className={`btn btn-primary ${isSubmitting ? "disabled": ""}`} data-disable-with="Change my password">
                Change my password
              </button>
            </div>
          </form>
        </div>
      </div>
    )
}

export default ChangePassword