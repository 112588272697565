import React from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  littlebirdieLogo,
  socialFacebook,
  socialInstagram,
  socialTwitter,
  socialYoutube,
  personalisedHeartsWhite,
  userIcon,
  downloadAppleStore,
  downloadAppQrCode,
  cbaLogoProudPartner,
  storeTiktokIcon
} from '../../../components/Icons'
import { handleNativeRedirectWebView } from '../utilis/NativeDataShare'
import { getIsMobile } from '../reducers'
// import { isIOS } from '../utilis/detectBrowser'

const SignInButton = () => {
  return (
    <Link
      className='btn-outline-dark'
      to={{
        pathname: '/login',
        state: { from: window.location.pathname }
      }}
    >
      <img src={userIcon} className='rounded-circle mr-1 ' width='24px' height='24px' />
      Sign In
    </Link>
  )
}

const PersonaliseButton = () => {
  return (
    <Link className='btn-secondary' to='/register'>
      <img src={personalisedHeartsWhite} className='mr-1' width='24px' height='24px' />
      Personalise my feed
    </Link>
  )
}

const Footer = ({ currentUser }) => {
  const isMobile = useSelector(getIsMobile)
  // const isIosDevice = isIOS()

  const history = useHistory()

  const handleNativeRedirect = (redirectUrl, type, redirectSource) => {
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"${type}", "path":"${window.location.href}"}`)
    } else {
      if (redirectSource === 'external') {
        window.open(`${redirectUrl}`, '_parent')
      } else if (redirectSource === 'internal') {
        history.push(`${type}`)
      }
    }
  }

  const hidePersonaliseBtn = ['/register'].includes(useLocation().pathname)

  return (
    <footer className='footer'>
      <div className='footer-app-download'>
        <div className='row'>
          <div className='col-12 d-inline-flex p-0 mx-0 my-auto first'>
            <span className='title'>
              Get the <span className='lb-budgee-text'>best price </span>
              while you shop
            </span>
            {isMobile && <a href='https://littlebirdie.onelink.me/tPpN/k9om45kl' target='_blank' rel='noreferrer'><img className='download-app-store' src={downloadAppleStore} /></a>}
            {!isMobile && <><img className='download-app-qr-code' src={downloadAppQrCode} />
              <div className='download-app-text'><strong>Download the <br /> Little Birdie App</strong></div>
            </>}
          </div>
        </div>
      </div>
      <div className='footer-container'>
        <div className='bg-main footer-brand'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-8 col-lg-6 col-xl-9 logo d-flex'>
                <div className='lb-logo'>
                  <img src={littlebirdieLogo} />
                </div>
                <div className='partner-logo'>
                  <img src={cbaLogoProudPartner} />
                </div>
              </div>
              <div className='d-none d-md-block col-md-4 col-lg-6 col-xl-3 my-auto social-actions'>
                <div className=''>
                  <a href='https://www.tiktok.com/@littlebirdie_toldme' target='_blank' rel='noreferrer'>
                    <img src={storeTiktokIcon} className='pr-2' />
                    Follow <span>@littlebirdie_toldme</span>
                  </a>
                </div>
                <div className=''>
                  <a href='https://www.instagram.com/littlebirdie_toldme' target='_blank' rel='noreferrer'>
                    <img src={socialInstagram} className='pr-2' />
                    Follow <span>@littlebirdie_toldme</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='d-block d-md-none col-12 text-center my-4 auth-action auth-action-mobile'>
              <SignInButton />
              <PersonaliseButton />
            </div>
            <div className='d-block d-md-none col-6 pb-4 social-actions-mobile'>
              <div>
                <a className='d-flex justify-content-start align-items-center' href='https://www.tiktok.com/@littlebirdie_toldme' target='_blank' rel='noreferrer'>
                  <img src={storeTiktokIcon} className='pr-2' />
                  <div>Follow <div className='strong'>@littlebirdie_toldme</div></div>
                </a>
              </div>
            </div>
            <div className='d-block d-md-none col-6 pb-4 social-actions-mobile'>
              <div>
                <a className='d-flex justify-content-start align-items-center' href='https://www.instagram.com/littlebirdie_toldme' target='_blank' rel='noreferrer'>
                  <img src={socialInstagram} className='pr-2' />
                  <div>Follow <div className='strong'>@littlebirdie_toldme</div></div>
                </a>
              </div>
            </div>

            <div className='col-md-6 col-lg-3'>
              {/* <img src={littlebirdieLogo} /> */}
              {/* <ul className='list-inline mt-3 mb-4'>
                <li className='list-inline-item'>
                  <div className='btn btn-social'>
                    <a href='https://www.facebook.com/littlebirdiecommunity' target='_blank' rel='noreferrer'>
                      <img src={socialFacebook} className='mx-0' />
                    </a>
                  </div>
                </li>
                <li className='list-inline-item'>
                  <div className='btn btn-social'>
                    <a href='https://twitter.com/littlebirdie_au' target='_blank' rel='noreferrer'>
                      <img src={socialTwitter} />
                    </a>
                  </div>
                </li>
                <li className='list-inline-item'>
                  <div className='btn btn-social'>
                    <a href='https://www.instagram.com/littlebirdie_toldme' target='_blank' rel='noreferrer'>
                      <img src={socialInstagram} />
                    </a>
                  </div>
                </li>
                <li className='list-inline-item'>
                  <div className='btn btn-social'>
                    <a href='https://www.youtube.com/channel/UCWCFTyHvi3PrUqdDFMDdnqQ' target='_blank' rel='noreferrer'>
                      <img src={socialYoutube} />
                    </a>
                  </div>
                </li>
              </ul> */}
              <ul className='list-unstyled'>
                <li className='d-none d-md-block auth-action auth-action-desktop-login'>
                  <SignInButton />
                </li>
                <li className='d-none d-md-block auth-action auth-action-desktop-personalise'>
                  <PersonaliseButton />
                </li>
                <li><a href={undefined} className='pointer' onClick={() => handleNativeRedirect('https://help.littlebirdie.com.au/hc/en-us/articles/360057583671', 'about', 'external')}> About Us</a></li>
                <li><a href={undefined} className='pointer' onClick={() => handleNativeRedirect('privacy', '/privacy', 'internal')}> Privacy Policy</a></li>
                <li><a href={undefined} className='pointer' onClick={() => handleNativeRedirect('terms', '/terms', 'internal')}> Terms &amp; Conditions</a></li>
                <li><a href={undefined} className='pointer' onClick={() => handleNativeRedirect('https://help.littlebirdie.com.au/hc/en-us/articles/360057583591', 'community', 'external')}> Community Rules</a></li>
                <li><a href='https://help.littlebirdie.com.au/hc/en-us/requests/new'> Advertise</a></li>
                <li><a href='https://help.littlebirdie.com.au'> Contact Us</a></li>
              </ul>
            </div>
            <div className='col-md-6 col-lg-9'>
              <div className='row'>
                <div className='col-lg-4'>
                  <h4>Popular Brands</h4>
                  <ul className='list-unstyled quicklinks'>
                    <li><a href='/shop/brand/apple'>Apple</a></li>
                    <li><a href='/shop/brand/lego'>LEGO&#174;</a></li>
                    <li><a href='/shop/brand/dyson'>Dyson</a></li>
                    <li><a href='/shop/brand/nike'>Nike</a></li>
                    <li><a href='/shop/brand/breville'>Breville</a></li>
                    <li><a href='/shop/brand/samsung'>Samsung</a></li>
                    <li><a href='/shop/brand/asics'>Asics</a></li>
                    <li><a href='/shop/brand/adidas'>Adidas</a></li>
                    <li><a href='/shop/brand/the-north-face'>The North Face</a></li>
                    <li><a href='/shop/brand/chanel'>Chanel</a></li>
                  </ul>
                </div>
                <div className='col-lg-4'>
                  <h4>Popular Stores</h4>
                  <ul className='list-unstyled quicklinks'>
                    <li><a href='/shop/store/jb-hifi'>JB Hi-Fi</a></li>
                    <li><a href='/shop/store/amazon-australia'>Amazon Australia</a></li>
                    <li><a href='/shop/store/catch'>Catch</a></li>
                    <li><a href='/shop/store/kmart'>Kmart</a></li>
                    <li><a href='/shop/store/ebay-australia'>eBay</a></li>
                    <li><a href='/shop/store/target'>Target</a></li>
                    <li><a href='/shop/store/big-w'>Big W</a></li>
                    <li><a href='/shop/store/harvey-norman'>Harvey Norman</a></li>
                    <li><a href='/shop/store/kogan'>Kogan</a></li>
                    <li><a href='/shop/store/the-iconic'>The Iconic</a></li>
                  </ul>
                </div>
                <div className='col-lg-4'>
                  <h4>Popular Pages</h4>
                  <ul className='list-unstyled quicklinks'>
                    {/* ToDo: Remove Deal: Remove link to top price drops */}
                    {/* <li><a href='/top-price-drops'>Top Price Drops</a></li> */}
                    <li><a href='/top-sales-events'>Top Sale Events</a></li>
                    <li><a href='/top-promo-codes'>Top Promo Codes</a></li>
                    <li><a href='/submit-listing'>Submit A Post</a></li>
                    <li><a href='/shop/electronics'>Electronics</a></li>
                    <li><a href='/shop/fashion'>Fashion</a></li>
                    <li><a href='/shop/home-kitchen'>Home & Kitchen</a></li>
                    <li><a href='/shop/appliances'>Appliances</a></li>
                    <li><a href='/shop/health-beauty'>Health & Beauty</a></li>
                    <li><a href='/kids-baby'>Kids & Baby</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 disclaimer'>
              <p>© Little Birdie
                {' '}
                {new Date().getFullYear()}
                {' '}
              </p>
              <p className='disclaimer-heading'>IMPORTANT DISCLAIMER</p>
              <p>Little Birdie does not compare all products, nor all providers of products in the market. We do not guarantee that the retailers listed on our site will always offer the cheapest prices available in the market. The product and pricing information shown on our site is selected from various retailers at the times indicated on our site.</p>
              <p>While we endeavour to ensure that the product and pricing information is current and accurate as at the time indicated, you should confirm this independently with the retailer. Refer to our website terms & conditions for more information.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
