import React from "react"
import { useSelector } from "react-redux"

import SaleEventNormalCard from "../../../../components/ListingCards/SaleEventCard/SaleEventNormalCard"
import { clockIcon } from "../../../../components/Icons"
import { getResource } from "../../reducers"

export default function ActiveCard({ eventId }) {
  const type = "saleEvent"
  const saleEvent = useSelector(state => getResource(state, { type, id: eventId }))

  if (!saleEvent) return null
  const { attributes: { timeLabel } } = saleEvent
  return (
    <div className="col-md-6 listing-card featured h-auto">
      <div className="user-cal-clock">
        {
          (!_.isEmpty(timeLabel))
            ? <img src={clockIcon} />
            : null
        }

        <span className="ml-1">
          {timeLabel}
        </span>
      </div>
      <SaleEventNormalCard item={saleEvent} wrapperClass="p-0" position={99} />
      {/*<SaleEventNormalCard item={saleEvent} wrapperClass="p-0" position={99} shareable />*/}
    </div>
  )
}
