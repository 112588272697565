/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { getBaseUrl } from "../../reducers"
import { getTopCoupons } from "../../requesters"
import ListingCard from "../ListingCard"
import TopLists from "../../../../components/TopLists"
import Loader from "../../../../components/Loader"
import ChevronRight from "../ChevronRight"

export default function StoreCouponsList({
 store, title, currentSection, sectionPosition
}) {
  const [storeCoupons, setStoreCoupons] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const baseUrl = useSelector(getBaseUrl)
  const myRef = useRef(null)

  useEffect(() => {
    const params = {
      by_published: true,
      by_store: store.id
    }
    async function fetchData() {
      const { response } = await getTopCoupons(baseUrl, params)
      if (_.get(response, "data.coupon")) {
        setStoreCoupons(response.ids)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  function renderTopListCoupons() {
    if (_.isEmpty(storeCoupons)) { return ([]) }
    return _.map(storeCoupons, (couponId, index) => <ListingCard type="coupon" id={couponId} position={index + 1} key={`top-sales-{}-${couponId}`} currentSection={currentSection} sectionPosition={sectionPosition} />)
  }

  if (_.isEmpty(renderTopListCoupons()) && isLoading) return (<Loader />)
  if (!_.isEmpty(renderTopListCoupons())) {
    return (
      <TopLists
        title={title}
        titleClass="mb-1"
        description={"We've browsed thousands of vouchers to bring you only the best right here."}
        isLoading={isLoading}
        wrapperClass="listing-carousel"
        viewAllUrl={`/shop/store/${_.get(store, "attributes.slug")}/top-promo-codes`}
        sectionClass="section-group"
        type="vouchers">
        <div className="d-flex listing-group-carousel" ref={myRef}>
          <ChevronRight offset={300} ref={myRef} direction="left" />
          { renderTopListCoupons() }
          <ChevronRight offset={300} ref={myRef} direction="right" />
        </div>
      </TopLists>
    )
  }
  return null
}
