import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom'
import { chevronLeft, littlebirdieNav, personalisedHeartsWhite } from '../../../../components/Icons'

import useWindowSize from '../../hooks/useWindowSize'

import DepartmentSelector from './DepartmentSelector'
import SignedInList from './SignedInList'
import SignedOutList from './SignedOutList'
import SearchInput from './SearchInput'
import MobileContent from './MobileNav'

import { getCurrentUser, getIsSearchInputActive, getIsOverlayOpen } from '../../reducers'
import UserMenuSlider from './MobileNav/UserMenuSlider'
import { handleNativeRedirectWebView } from '../../utilis/NativeDataShare'

const MobileNav = () => <div onClick={() => window.history.back()} className='px-3 bg-main d-flex align-items-center MobileNav'>
  <img src={chevronLeft} />
  <div className='title'>Back</div>
</div>

export default function MainNavbar (props) {
  const { isHomepage } = props
  const history = useHistory()
  const currentUser = useSelector(getCurrentUser)
  const isSearchInputActive = useSelector(getIsSearchInputActive)

  const { width } = useWindowSize()
  const location = useLocation()
  const hideNavbar = ['/login', '/register'].includes(location.pathname)
  const breakWidth = 1200

  const [mobileHeader, setMobileHeader] = useState({
    prevScrollPos: 0,
    searchBarSticky: true,
    showShadow: false
  })

  const [showSearchBar, setShowSearchBar] = useState(false)
  const isOpen = useSelector(getIsOverlayOpen)
  const hidePersonaliseBtn = ['/register'].includes(location.pathname)
  // Function to handle search input  on scroll
  useEffect(() => {
    if (isHomepage) {
      window.addEventListener('scroll', handleSearchInputScroll)
      return () => window.removeEventListener('scroll', handleSearchInputScroll)
    }
  }, [])

  useEffect(() => {
    if (!(isOpen)) {
      width < breakWidth && setShowSearchBar(isSearchInputActive)
    }
  }, [isSearchInputActive])

  const handleSearchInputScroll = () => {
    if (!(isOpen)) {
      const currentScrollPos = window.pageYOffset
      currentScrollPos > 234 ? setShowSearchBar(true) : setShowSearchBar(false)
    }
  }

  // Function to handle show/hide navigation bar on mobile header
  // Commented this for future use
  // useEffect(() => {
  //   if (width < breakWidth) {
  //     window.addEventListener('scroll', handleMobileHeaderScroll)
  //     return () => window.removeEventListener('scroll', handleMobileHeaderScroll)
  //   }
  // }, [mobileHeader.prevScrollPos, width, breakWidth, isOpen])

  const handleMobileHeaderScroll = () => {
    if (!(isOpen)) {
      const currentScrollPos = window.pageYOffset
      setMobileHeader({
        ...mobileHeader,
        searchBarSticky: mobileHeader.prevScrollPos > currentScrollPos,
        showShadow: mobileHeader.prevScrollPos > currentScrollPos && currentScrollPos > 57,
        prevScrollPos: currentScrollPos
      })
      if (currentScrollPos < 57) setMobileHeader({ ...mobileHeader, searchBarSticky: true })
    }
  }

  const handleNativeShare = () => {
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"register", "path":"${window.location.href}"}`)
    } else {
      history.push('/register')
    }
  }

  function DesktopContent() {
    return (
      <div className='container-fluid'>
        <Link className='navbar-brand bg-transparent' to='/'>
          <img src={littlebirdieNav} />
        </Link>
        <SearchInput showSearchBar={isHomepage ? showSearchBar : true} />
        {/* <SearchInput showSearchBar={!isHomepage} /> */}
        {isSearchInputActive && <div style={{ width: '-webkit-fill-available' }} />}
        <div className='collapse navbar-collapse'>
          <ul className='navbar-nav mr-auto mt-5 mt-xl-0'>
            {!currentUser && !currentUser?.type && !hidePersonaliseBtn && !isHomepage &&
              <li className='nav-item nav-listing'>
                <div className='nav-link' onClick={handleNativeShare}>
                  <button className='btn btn-secondary rounded-pill btn-feed'>
                    <img src={personalisedHeartsWhite} className='mr-2' width='20px' height='20px' />
                    Personalise my feed
                  </button>
                </div>
              </li>
            }
            <DepartmentSelector />
            {/* ToDo: Remove Deal: Remove Price Drops Link */}
            {/* <li className='nav-item nav-listing'>
              <NavLink className='nav-link' exact to='/top-price-drops'>
                Price Drops
                <span className='sr-only' current='' />
              </NavLink>
            </li> */}
            <li className='nav-item nav-listing'>
              <NavLink className='nav-link' exact to='/extension'>
                Browser Extension
                <div className='new-pill'>
                  <span className="new-pill-text"> New </span>
                </div>
                <span className='sr-only' current='' />
              </NavLink>
            </li>
            <li className='nav-item nav-listing'>
              <NavLink className='nav-link' exact to='/top-sales-events'>
                Sale Events
                <span className='sr-only' current='' />
              </NavLink>
            </li>
            <li className='nav-item nav-listing'>
              <NavLink className='nav-link' exact to='/top-promo-codes'>
                Promo Codes
              </NavLink>
            </li>
          </ul>
          {currentUser && currentUser.type
            ? <SignedInList currentUser={currentUser} />
            : <SignedOutList />}
        </div>
      </div>
    )
  }
  return (
    <>
      {width < breakWidth && (
        <>
          <UserMenuSlider />
          {/* <CatMenuSlider /> */}
        </>
      )}
      {width < 768 && hideNavbar ? <MobileNav /> : (
        <div className='fixed-top main-nav'>
          {
            width < breakWidth ?
              <nav className='navbar navbar-expand-xl navbar-light mb-search-bar-shadow'>
                <MobileContent showSearchBar={isHomepage ? showSearchBar || isOpen : true} isHomepage={isHomepage} currentUser={currentUser} searchBarSticky={mobileHeader.searchBarSticky} />
              </nav>
              :
              <nav className='navbar navbar-expand-xl navbar-light'>
                <DesktopContent />
              </nav>
          }
        </div>)}
      {/* <div className='site-counter'>
        {route.location.pathname === '/' &&
          (
            <DealTracker />
          )}
      </div> */}
    </>
  )
}
