import React from 'react'

export default function CopyCouponCode ({
  item: {
    attributes: {
      code,
      couponCode,
      type
    }
  },
  wrapperClass, uniqueCouponType, couponUrl, checkId
}) {
  const isUniqueCode = uniqueCouponType === 'UniqueCoupon'

  if ((!couponCode && type === 'coupon') || (!code && type === 'coupon')) { return null }
  return (
    <div className='d-flex align-items-center unique-coupon-item' key={checkId}>
      {(code || couponCode) && (
        <div className={`${wrapperClass}`}>
          {isUniqueCode && !code && couponUrl && (
            <a className='link-wrapper' href={couponUrl} target='_blank' rel='noreferrer'>
              <span>View Code</span>
            </a>
          )}
          <>
            <span>
              <b className='pr-1'>CODE: </b>
              {`${isUniqueCode ? ` ${code}` : ` ${couponCode}`}`}
            </span>
          </>
        </div>
      )}
    </div>
  )
}
