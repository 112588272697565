import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { getCurrentUser } from '../../../reducers'
import { googleLogin, facebookLogin } from '../../../../../components/Icons'
import { gon } from '../../../../../../constant'

function EditUsername (props) {
  const { prevUrl } = props
  const [username, setUsername] = useState('')
  const [errors, setErrors] = useState([])

  const currentUser = useSelector(getCurrentUser)

  useEffect(() => {
    setUsername(currentUser?.attributes?.username || '')
  }, [currentUser])

  const handleSubmit = e => {
    e.preventDefault()

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
      setErrors(['Email cannot be used as username'])
      return false
    }

    setErrors([])

    const fetchData = async setUsernameData => {
      const path = `${gon.urls.api}/auth/update`

      return axios.put(path, setUsernameData)
        .then(response => {
          if (response.status !== 200) {
            setErrors(response.data?.errors)
            return null
          }

          currentUser.attributes.username = username
          window.localStorage.setItem('current_user', JSON.stringify(currentUser))
          window.location.href = typeof (prevUrl) === 'object' ? prevUrl.prevUrl || '/' : prevUrl || '/'
          return null
        })
        .catch(_error => {
          setErrors(['Unable to update username. Please try again.'])
        })
    }
    const setUsername = {
      username: username,
      user_id: currentUser.id
    }
    fetchData(setUsername)
  }

  return (
    <div className='container'>
      <div className='row justify-content-center mt-5'>
        <div className='col-sm-9 col-md-7 col-lg-5 col-xl-4'>
          <div className='card'>
            <div className='card-body'>
              <div className='text-center mt-4'>
                {
                  currentUser && (
                    currentUser.attributes?.last_login_strategy === 'facebook'
                      ? (
                        <>
                          <div className='my-4 btn rounded-circle shadow-sm p-2 border mx-2'>
                            <img src={facebookLogin} />
                          </div>
                          <div className='strong mb-3'>Facebook, great choice!</div>
                        </>
                        )
                      : (
                          currentUser.attributes?.last_login_strategy === 'google_oauth2'
                            ? (
                              <>
                                <div className='my-4 btn rounded-circle shadow-sm p-2 border mx-2'>
                                  <img src={googleLogin} />
                                </div>
                                <div className='strong mb-3'>Google, great choice!</div>
                              </>
                              )
                            : (
                              <div className='strong mb-3'>Thanks for login!</div>
                              )
                        )
                  )
                }
                <p>Please select a username. This will be what is displayed to other users</p>
              </div>
              <form className='simple_form edit_user' id='edit_user_50216' onSubmit={handleSubmit} noValidate='novalidate' acceptCharset='UTF-8'>
                <div className='form-group string optional user_username'>
                  <label className='string optional' htmlFor='user_username'>Username</label>
                  <input className={`form-control string optional ${errors?.length > 0 ? 'is-invalid' : currentUser?.attributes?.username === username && 'is-valid'} `} type='text' name='user[username]' id='user_username' value={username} onChange={e => setUsername(e.target.value)} />
                  <div class='invalid-feedback'>{errors && errors.map((error, index) => <p key={index}>{error}</p>)}</div>
                </div>
                <div className='text-center'>
                  <div className='form-group'>
                    <input type='submit' name='commit' value='Join' className='btn btn-main rounded-pill btn-lg w-50' disabled={username.length > 0 ? '' : 'disabled'} data-disable-with='Join' />
                  </div>
                  <p className='small'>By joining in you agree to our <a href='/terms'>Terms and Conditions</a>, <a href='https://help.littlebirdie.com.au'>Community Standards</a> and  <a href='/privacy'>Privacy Policy</a>.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditUsername
