import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MovingText from 'react-moving-text'
import { chevronRight, homeIcon } from '../../../../components/Icons'
import searchPageImage from '../../../../../assets/images/LB-search-animation-mobile\(Once\).gif'
import personaliseImage from '../../../../../assets/images/LB-personalise-animation.gif'
import TodaysTopPicks from '../TodaysTopPicks'
import { narratTopPicksRequestStart, setModalClose, setSearchActive } from '../../actions'
import { getCurrentUser } from '../../reducers'
import useElementOnScreen from '../BiInfiniteScroll/useElementOnScreen'
import { handleNativeRedirectWebView } from '../../utilis/NativeDataShare'
import { useInView } from 'react-intersection-observer'
import narrativeDataLayer from '../../../../components/dataLayers/narrativeDataLayer'
import { USE_IN_VIEW_OPTIONS } from '../../../../../constant'

const StatementOne = () => {
  const [animationType, setAnimationType] = useState('fadeInFromBottom')

  return (
    <MovingText
      type={animationType}
      duration='300ms'
      delay='0'
      direction='normal'
      timing='ease-in-out'
      iteration='1'
      fillMode='none'
      onAnimationEnd={()=> { setTimeout(()=> setAnimationType('slideOutToTop'), 3400) }}
    >
      <div>
        <span className='highlighter'>Compare</span>
        <span> 70 Million</span>
      </div>
      <div>
        <span className='highlighter'>product listings</span>
        <span> in one place</span>
      </div>
    </MovingText>
  )
}

const StatementTwo = () => {
  const [animationType, setAnimationType] = useState('fadeInFromBottom')

  return (
    <>
      <MovingText
        type={animationType}
        duration='300ms'
        delay='0'
        direction='normal'
        timing='ease'
        iteration='1'
        fillMode='none'
        onAnimationEnd={()=> { setTimeout(()=> setAnimationType('slideOutToTop'), 3400) }}
      >
        <span>Where to get the</span>
        <span className='highlighter'> best price</span>
      </MovingText>
    </>
  )
}

const StatementThree = () => {
  const [animationType, setAnimationType] = useState('fadeInFromBottom')

  return (
    <>
      <MovingText
        type={animationType}
        duration='300ms'
        delay='0'
        direction='normal'
        timing='ease'
        iteration='1'
        fillMode='none'
        onAnimationEnd={()=> { setTimeout(()=> setAnimationType('slideOutToTop'), 3400) }}
      >
        <span>The<span className='highlighter d-inline'> best </span></span>
        <span> shopping <span className='highlighter d-inline'>offers</span> </span>
        <span> for me</span>
      </MovingText>
    </>
  )
}

const captionsComp = [<StatementOne key='StatementOne' />, <StatementTwo key='StatementTwo' />, <StatementThree key='StatementThree' />]

const HomeSection = () => {
  const animSequence = ['animation-1', 'animation-2', 'animation-3']

  const [animation, setAnimation] = useState('')
  const [caption, setCaption] = useState(0)
  const location = useHistory()

  useEffect(() => {
    let timer = null
    const getIndex = animSequence.indexOf(animation)
    const getCurrentValue = getIndex < 0 ? 0 : getIndex + 1
    if (getCurrentValue < animSequence.length) {
      timer = setTimeout(() => setAnimation(animSequence[getCurrentValue]), 2000)
    }

    return () => clearTimeout(timer)
  }, [animation])

  useEffect(() => {
    let timer = null
    if (caption < captionsComp.length - 1) {
      timer = setTimeout(() => setCaption(caption + 1), 4000)
    } else {
      timer = setTimeout(() => setCaption(0), 4000)
    }

    return () => clearTimeout(timer)
  }, [caption])

  const [refNarrSection, inView] = useInView(USE_IN_VIEW_OPTIONS)
  useEffect(() => {
    if (inView) {
      narrativeDataLayer('narratView', null, 'Narrative Intro', 1)
    }
  }, [inView])

  const handleNavigation = () => location.push('/')

  return (
    <div className='bg-main narrative-section bg-cloud border-t-0 mb-0'>
      <div>
        <div className='d-flex w-100 justify-content-end align-items-center top-nav'>
          <span id='narrat-home-btn' onClick={handleNavigation}>
            <img src={homeIcon} />
            <img src={chevronRight} />
          </span>
        </div>
        <div ref={refNarrSection} className='content p-b-20'>

          <div className={`d-flex flex-column title m-t-64 home ${animation}`}>
            <div>A</div>
            <div className='told-me'>Little Birdie told me</div>
          </div>
          <div className='d-flex flex-column caption p-b-2 margin-top-30'>
            {captionsComp[caption]}
          </div>
        </div>
      </div>
    </div>
  )
}

const ShoppingSection = () => {
  const animSequence = ['', 'animation-1', 'animation-2']

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }

  const [secRef, isSecVisible] = useElementOnScreen(options)

  const [animation, setAnimation] = useState(null)
  const location = useHistory()

  useEffect(() => {
    let timer = null
    const getIndex = animSequence.indexOf(animation)
    const getCurrentValue = getIndex < 0 ? 0 : getIndex + 1
    if (animation !== null && getCurrentValue < animSequence.length) {
      timer = setTimeout(() => setAnimation(animSequence[getCurrentValue]), 2000);
    }

    return () => clearTimeout(timer)
  }, [animation])

  useEffect(() => {
    if (isSecVisible && !animation) setAnimation('')
  }, [isSecVisible])

  const handleSearchBtn = () => {
    narrativeDataLayer('narratClick', 'Start searching', 'Narrative Search', 2)
    // dispatch(setSearchActive(true))
    location.push('/')
  }

  const [refNarrSearchSection, inView] = useInView(USE_IN_VIEW_OPTIONS)
  useEffect(() => {
    if (inView) {
      narrativeDataLayer('narratView', null, 'Narrative Search', 2)
    }
  }, [inView])

  return (
    <div className='bg-peach-500 narrative-section shopping-bg section-1'>
      <div ref={refNarrSearchSection} className='content-2'>
        <div className='info-col' ref={secRef}>
          <div className='sub-title'>
            <span>
              <span className='d-inline'>
                <span className='highlighter d-inline'>Search </span>&{' '}
              </span>
              <span className='highlighter'>Compare </span>
            </span>
            <span className='d-block'>online shopping</span>
          </div>
          <div className='d-flex flex-column paragraph p-b-2'>
            Pay the best price by comparing 70 million product listings from over 1000 retailers
          </div>
          <div className='desktop-show'>
            <button
              type='button'
              className='btn btn-outline-dark rounded-pill'
              onClick={handleSearchBtn}
            >
              Start searching
            </button>
          </div>
        </div>
        <div className='col-img'>
          <div className='box shopping '>
            <div className={animation} />
            <div className='desktop' />
            <div className='wrap-img'>
              <img src={searchPageImage} />
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-outline-dark rounded-pill mx-2 px-4 mobile-show'
              onClick={handleSearchBtn}
            >
              Start searching
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const TopDeals = () => {
  const location = useHistory()
  const handleNavigation = () => {
    narrativeDataLayer('narratClick', 'View todays offers', 'Narrative Discovery', 3)
    location.push('/?scroll=top-10-in-australia-today')
  }

  const [refNarrDealSection, inView] = useInView(USE_IN_VIEW_OPTIONS)
  useEffect(() => {
    if (inView) {
      narrativeDataLayer('narratView', null, 'Narrative Discovery', 3)
    }
  }, [inView])

  return (
    <div className='narrative-section shadow-none discover-bg'>
      <div ref={refNarrDealSection} className='discover'>
        <div className='sub-title p-x-22'>
          <span>
            <span className='highlighter d-inline'>Discover</span> the
          </span>
          <span> best in shopping </span>
        </div>
        <div className='paragraph p-b-2'>
          Never miss today’s top ranked price drops,
          sale events and voucher codes
        </div>
        <div className='discover-card '>
          <TodaysTopPicks showViewBtn={handleNavigation} fromNarrat={true} isYourTopPickShow={true} />
        </div>
      </div>
    </div>
  )
}

const PersonliaseSection = () => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }

  const [secRef, isSecVisible] = useElementOnScreen(options)
  const currentUser = useSelector(getCurrentUser)

  const animSequence = ['animation-1', 'animation-2', 'animation-3']
  const dispatch = useDispatch()
  const location = useHistory()
  const [animation, setAnimation] = useState(null)

  useEffect(() => {
    let timer = null
    const getIndex = animSequence.indexOf(animation)
    const getCurrentValue = getIndex < 0 ? 0 : getIndex + 1
    if (animSequence !== null && getCurrentValue < animSequence.length) {
      timer = setTimeout(() => setAnimation(animSequence[getCurrentValue]), 2000)
    }

    return () => clearTimeout(timer)
  }, [animation])

  useEffect(() => {
    if (isSecVisible && !animation) setAnimation('')
  }, [isSecVisible])

  const handlePersonalise = () => {
    narrativeDataLayer('narratClick', 'Personalise now', 'Narrative Personalise', 4)
    if (currentUser?.id) {
      dispatch(setModalClose(true))
      location.push('/')
    } else {
      if (window.ReactNativeWebView) {
        handleNativeRedirectWebView(`{"type":"register", "path":"${window.location.href}"}`)
      } else {
        location.push('/register')
      }
    }
  }

  const [refNarrPersonaliseSection, inView] = useInView(USE_IN_VIEW_OPTIONS)
  useEffect(() => {
    if (inView) {
      narrativeDataLayer('narratView', null, 'Narrative Personalise', 4)
    }
  }, [inView])

  return (
    <div className='bg-peach-700 narrative-section personalis-bg section-2'>
      <div ref={refNarrPersonaliseSection} className={`personalise ${animation}`}>
        <div className='content-2'>
          <div className='info-col' ref={secRef}>
            <div className='sub-title'>
              <div className='highlighter'>Personalise</div>
              <span>your shopping </span>
              <span>feed</span>
            </div>
            <div className='d-flex flex-column paragraph fav-brands p-b-2'>
              Little Birdie hand picks offers from your favourite brands and stores
            </div>
            <div className='desktop-show'>
              <button
                type='button'
                className='btn btn-outline-dark rounded-pill'
                onClick={handlePersonalise}
              >
                Personalise now
              </button>
            </div>
          </div>
          <div className='img-col'>
            <div className='box'>
              <div className='p-desktop' />
              <div className='wrap-img'>
                <img src={personaliseImage} />
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <button
                type='button'
                className='btn btn-outline-dark rounded-pill mx-2 px-4 mobile-show'
                onClick={handlePersonalise}
              >
                Personalise now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const WelcomePage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(narratTopPicksRequestStart({}))
    const narratPageCounter = localStorage.getItem('narratPageCounter') || 0
    const HomeToNarrat = JSON.parse(sessionStorage.getItem('fromHomeToNarrat'))
    if (HomeToNarrat) {
      sessionStorage.setItem('fromHomeToNarrat', false)
      sessionStorage.setItem('isNarratView', true)
      localStorage.setItem('narratPageCounter', parseInt(narratPageCounter) + 1)
    }
  }, [])

  return (
    <div className='narrative'>
      <HomeSection />
      <div className='body'>
        <ShoppingSection />
        <TopDeals />
        <PersonliaseSection />

        <div className='d-flex justify-content-center align-items-center nar-lable'>
          <a onClick={() => narrativeDataLayer('narratClick', 'start-shopping', 'text-cta', 5)} href='/' className='text-pr-500'>
            Start shopping
          </a>
          <img src={chevronRight} />
        </div>
      </div>
    </div>
  )
}

export default WelcomePage
