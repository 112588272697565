import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import Breadcrumb from "../../../../../components/ListingPageBreadcrumb"

import Meta from "../../../../../components/Meta"
import { getBaseUrl } from "../../../reducers"

export default function NewsletterPage({ match: { params: { slug } } }) {
  const baseUrl = useSelector(getBaseUrl)
  const [newsletter, setNewsletter] = useState(null)
  const [store, setStore] = useState(null)

  useEffect(() => {
    axios.get(`${baseUrl}/newsletters/${slug}`)
      .then(response => {
        const { data } = response
        if (!data.data || data.data.length === 0) {
          return null
        }
        setStore(_.first(data.included))
        setNewsletter(data.data)
        return null
      })
  }, [slug])

  if (newsletter) document.querySelector("#newsletter-iframe").contentDocument.write(newsletter.attributes.content)

  return (
    <>
      {newsletter && (
        <Meta
          name="View Individual Newsletter"
          description={`Checkout the latest offers from ${_.startCase(store.attributes.name)} at Little Birdie`}
          title={`${_.startCase(newsletter.attributes.subject)} - ${_.startCase(store.attributes.name)} | Little Birdie`}
          og_description={`Checkout the latest offers from ${_.startCase(store.attributes.name)} at Little Birdie`} />
      )}
      <div className="col-xl-9 ml-auto">
        <Breadcrumb store={store} listing={newsletter} />
      </div>
      <div className="row main-wrapper">
        <div className="col-xl-3 order-2 order-xl-1 sidebar"></div>
        <div className="col-xl-9 order-1 order-xl-2 ml-auto main-content pt-3 pb-5">
          <iframe
            frameBorder="0"
            id="newsletter-iframe"
            style={{ maxWidth: "640px", height: "calc(100vh - 260px)" }}
            width="100%"
            title={slug} />
        </div>
      </div>
    </>
  )
}
