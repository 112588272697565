import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Breadcrumb from "../../../../../components/Breadcrumb"
import ProfileHeader from "../../../../../components/UserProfileHeader"
import UserActivities from "../../../../../components/UserActivities"
import Meta from "../../../../../components/Meta"
import { loadProfileActivityPage } from "../../../actions"
import * as reducers from "../../../reducers"
import { TopStoresSidebarList, TopBrandsSidebarList, TopDealHuntersSidebarList } from "../../listings"


export default function ActivityPage({
  location,
  match: {
    params: { slug }
  }
}) {
  const dispatch = useDispatch()


  const user = useSelector(state => reducers.getUserBySlug(state, slug))
  const current_user = useSelector(state => reducers.getCurrentUser(state))

  useEffect(() => {
    dispatch(loadProfileActivityPage({ identifier: slug, data: current_user }))
  }, [slug, current_user])

  return (
    <>
      {user && (
        <Meta
          description={`${user.attributes.username} is a spotter on Little Birdie. See all their deals, price drops, votes, vouchers, sale events and more! `}
          name="My Activity"
          title={`${user.attributes.username}'s Activity | Little Birdie`} />
      )}
      <div className="container">
        <Breadcrumb breadcrumbs={location} />
        <ProfileHeader user={user} />
      </div>
      <UserActivities user={user} slug={slug} />
      <TopStoresSidebarList title="Stores you're following" showAll />
      <TopBrandsSidebarList title="Brands you're following" showAll />
      {/*<TopDealHuntersSidebarList title="Spotters you're following" showAll /> */}
    </>
  )
}
