export default function couponDataLayer(coupon, eventType, linkType) {
  const { attributes: { fixedGlobalScore, fixedDepartmentScore, name: couponName } } = coupon
  const departmentName = _.get(coupon, "relationships.department.attributes.name", null)
  const categoryName = _.get(coupon, "relationships.category.attributes.name", null)
  const subcategoryName = _.get(coupon, "relationships.subcategory.attributes.name", null)

  const names = [departmentName, categoryName, subcategoryName]

  const {
    id: storeId,
    attributes: {
      name: storeName
    } = {}
  } = coupon.relationships.store || {}

  if (linkType && eventType === "track-event") {
    snowplow('trackStructEvent', 'ux', 'coupon-use', linkType, null, null, [
      {
        schema: "iglu:com.littlebirdie/coupon/jsonschema/1-0-0",
        data: {
          id: String(coupon.id),
          category: names,
          rank_global: fixedGlobalScore,
          rank_department: fixedDepartmentScore,
          name: couponName
        }
    },
    {
      schema: "iglu:com.littlebirdie/retailer/jsonschema/1-0-0",
      data: {
        id: String(storeId),
        name: storeName
      }
    }]
    );
  }

  if (eventType === "coupon-view") {

    snowplow('trackStructEvent', 'ux', 'coupon-view', linkType, null, null, [
      {
        schema: "iglu:com.littlebirdie/coupon/jsonschema/1-0-0",
        data: {
          id: String(coupon.id),
          category: names,
          rank_global: fixedGlobalScore,
          rank_department: fixedDepartmentScore,
          name: couponName
        }
    },
    {
      schema: "iglu:com.littlebirdie/retailer/jsonschema/1-0-0",
      data: {
        id: String(storeId),
        name: storeName
      }
    }]
    );
  }
}