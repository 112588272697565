import React from 'react'
import MainApp from './javascript/apps/App/index'
import moment from 'moment'

import axios from 'axios'
import './bootstrap_custom.js'
import './javascript/stylesheets/application.scss'
import * as Sentry from '@sentry/browser'
import * as braze from '@braze/web-sdk'
import Promise from 'bluebird'
import { REACT_APP_BRAZE_API_KEY, REACT_APP_BRAZE_ENDPOINT, gon, LB_NATIVE_APP_VERSION } from './constant'

Sentry.init(gon.env_vars.sentry)
// Sentry.configureScope is deprecated, use getCurrentScope instead
const scope = Sentry.getCurrentScope()
scope.setUser({ id: gon?.current_user?.id })

// Sentry.configureScope((scope) => {
//   scope.setUser({ id: gon?.current_user?.id })
// })

if(LB_NATIVE_APP_VERSION)
  Sentry.setTag('lb_app_version', 'version_' + LB_NATIVE_APP_VERSION.toString())

braze.initialize(REACT_APP_BRAZE_API_KEY, { baseUrl: REACT_APP_BRAZE_ENDPOINT })

// Moment Global Configuration
// refer: https://momentjs.com/docs/#/customization/relative-time-threshold/
moment.relativeTimeThreshold('d', 30)

// Axios Configuration
const configureAxios = (axios) => {
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  if (gon?.csrf_token?.length > 0) {
    axios.defaults.headers.common['X-CSRF-Token'] = gon.csrf_token
  }
  if (gon?.auth_token?.length > 0) {
    axios.defaults.headers.common['X-Auth-Token'] = gon.auth_token
  }
}

// Bluebird Configuration
const configurePromise = () => {
  Promise.config({
    // Enable warnings
    warnings: true,
    // Enable long stack traces
    longStackTraces: false,
    // Enable cancellation
    cancellation: true,
    // Enable monitoring
    monitoring: true,
    // Enable async hooks
    asyncHooks: true
  })
}

configureAxios(axios)
configurePromise()

function App () {
  return <MainApp />
}

export default App
