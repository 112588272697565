import React from 'react'
import { useSelector } from 'react-redux'

import { clockIcon } from "../../../../components/Icons"
import SaleEventNormalCard from "../../../../components/ListingCards/SaleEventCard/SaleEventNormalCard"
import { getResource } from "../../reducers"
import ActionButtons from "./ActionButtons"

export default function ActiveCard ({ eventId }) {
  const type = 'saleEvent'
  const saleEvent = useSelector(state => getResource(state, { type, id: eventId }))

  if (!saleEvent) return null
  const { attributes: { timeLabel } } = saleEvent
  return (
    <div className="col-md-6 listing-card">
      <div className="user-cal-clock">
        <img src={clockIcon} />
        <span className="ml-1">
          {timeLabel}
        </span>
      </div>
      <SaleEventNormalCard item={saleEvent} wrapperClass="upcoming-sale" position={99} />
      {/*<SaleEventNormalCard item={saleEvent} wrapperClass="p-0" position={99} shareable />
      <ActionButtons saleEvent={saleEvent} />*/}
    </div>
  )
}
